
import {
  CHANGE_NUMFLAG,
} from './mutation-types';

export default {
  CHANGE_NUMFLAG: ({ commit, state }, numFlag) => {
    commit(CHANGE_NUMFLAG, numFlag);
  }
};
