<template>
  <div id="app">

    <Head :userInfo="userInfo" @clockupdate="clockupdate" v-if="hasHead"></Head>
    <el-container style="height: calc(100% - 50px);">
      <Aside v-if="isLogin"></Aside>
      <el-main>
        <keep-alive :include='includes'>
          <router-view style="height:100%" v-if="isRouterAlive" :userInfo="userInfo" @logindata='logindata' />
        </keep-alive>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Ajax from '@/api/create.js';
import Head from '@/components/Head.vue';
import Aside from '@/components/Aside.vue';

import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    };
  },
  watch: {
    '$route'(to, from) {
      if (this.$route.path == '/login' || this.$route.path == '/dataService-view' || this.$route.path == '/sign-up') {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
      if (this.$route.path == '/dataService-view') {
        this.hasHead = false;
      } else {
        this.hasHead = true;
      }

      // this.isLogin = localStorage.getItem('token') ? true :false;
      if (to.path != '/' && to.path != '/login' && to.path != '/admin-jump') {
        if (localStorage.getItem('token')) {
          this.getUserInfo();
        }
      }
    }
  },
  data() {
    return {
      userInfo: {},
      curroute: '',
      isRouterAlive: true,
      isLogin: false,
      hasHead: false,
    };
  },
  components: {
    Head,
    Aside
  },
  computed: {
    ...mapGetters([
      'numFlag', 'includes'
    ])
  },
  mounted() {
    if ((window.location.pathname == '/v/' || window.location.pathname == '/v/login') && localStorage.getItem('token')) {
      let role = JSON.parse(localStorage.getItem('userInfo')).role_id;
      this.$router.push('/project-list');
    }
  },
  methods: {
    logindata(val) {
      this.userInfo = val;
    },
    ...mapActions([
      'CHANGE_NUMFLAG'
    ]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getUserInfo() {
      let _this = this;
      Ajax.getUserInfo({}).then(function (res) {
        if (res.code == 0) {
          _this.userInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          // console.log('app页面存')
          let settings = {
            watermark_txt: `${_this.setConfig()}${_this.userInfo.user_id}`,
            rows: document.documentElement.clientHeight / 135,
          };
          _this.$nextTick(function () {
            let oTemp = _this.$watermark.set(settings);
            // this.$refs.watermark.appendChild(oTemp)
          });
        }
      });
    },
    setConfig() {
      if (localStorage.getItem('config')) {
        let config = JSON.parse(localStorage.getItem('config'));
        if (config.watermark) {
          return config.watermark;
        } else {
          return '';
        }
      }
    },
    //绩效考勤
    clockupdate() {
      this.CHANGE_NUMFLAG();
    },
    changeStore() {
      this.$store.commit('changeIncludes', 'tableLists');
    }
  }
};
</script>

<style lang="scss" type="text/css">
#app{
  min-width: 780px;
}
</style>
