export class DarkIframeSdk {
  constructor({ uuid, ele, url, onMessage }) {
    this.containerEle = ele;
    this.iframe = null;
    this.uuid = uuid;

    this.dealWithUrl(url);
    this.onMessageCb = onMessage;

    this.createIframe();
    this.onMessage();
  }

  createIframe() {
    const iframe = document.createElement("iframe");

    // 设置 iframe 的属性
    iframe.src = this.url; // 设置外部网页的 URL
    iframe.style.border = "none"; // 设置边框
    iframe.style.width = "100%"; // 设置边框
    iframe.style.height = "100%"; // 设置边框

    this.iframe = iframe;
    this.containerEle.appendChild(iframe);
  }

  sendMsg(type, data) {
    this.iframe?.contentWindow?.postMessage({ type, data }, "*");
  }

  onMessage() {
    window.addEventListener("message", this.handleOnMessage.bind(this));
  }

  destroyed() {
    window.removeEventListener("message", this.handleOnMessage);
    this.iframe.remove();
  }

  handleOnMessage(event) {
    if (event.origin !== this.origin) return;
    const { type, data, uuid } = event.data || {};
    if (uuid !== this.uuid) return;
    this.onMessageCb?.({ type, data });
  }

  dealWithUrl(u) {
    try {
      this.origin = window.location.origin;
      if (u.startsWith("http://") || u.startsWith("https://")) {
        this.origin = new URL(u).origin;
      }
      this.url = u;
    } catch (error) {
      console.error("解析Url失败", error);
    }
  }
}
