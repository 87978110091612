/**
* 作者 ：moon
*
* 日期 ：2019/12/16
*
* 描述 ：公共组件 - 弹窗
*/
<template>
  <el-dialog
    style="margin-top:0!important"
    :visible.sync="options.visible"
    :close-on-click-modal="false"
    :modal-append-to-body='false'
    :append-to-body="options.appendToBody"
    custom-class="myDialog">
    <div slot="title" class="dialogTitleBar">
      <i v-if="options.arrow" class="el-icon-arrow-left" @click="options.arrowBack" style="cursor: pointer;margin-left:-90px"></i>
      {{ options.title }}
    </div>
    <slot name="dialogContent" ref="dialogContent"></slot>
    <div slot="footer" ref="dialogFooter"></div>
  </el-dialog>
</template>

<script>
  export default {
    props: ["options"],
    name: "MDialog",
    components: {
    },
    data () {
      return {

      };
    },
    mounted () {
    },
    methods: {
      getRefs (refName) {
        if (this.$refs[refName]) {
          return this.$refs[refName][0];
        } else {
          throw new Error(`the control ${refName} is undefined !`);
        }
      }
    }
  };
</script>

<style lang="scss">
  .v-modal{
    z-index: 2000;
  }
  .el-dialog__wrapper::-webkit-scrollbar {
    display: none!important; /* Chrome Safari */
  }
  .myDialog{
    /*min-width: 655px;*/
    /*width: 40%;*/
    width: 500px!important;
    /*min-width: auto !important;*/
    .el-form{
      position: relative;

      margin:0 auto;
    }
    .formCenter{
      margin: 0 auto;
      left: 0px;
      width: 85%;
    }
  }
  .myDialog .dialogTitleBar{
    color:#494949;
    text-align: center;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
    text-indent: 30px;
    font-weight: bold;
  }
  .myDialog .el-form-item__label{
    font-size: 13px;
  }
  .myDialog .el-input .el-input__inner{
    font-size: 13px;
  }
  .myDialog .el-dialog__body {
    padding: 20px 30px;
    font-size: 13px;
  }
  .myDialog .el-dialog__header{
    padding: 0;
    position: relative;
  }
  .myDialog .el-dialog__headerbtn{
    top: 10px;
    right: 26px;
    font-size: 14px;
    font-weight: bold;
  }
  .myDialog .el-dialog__headerbtn .el-dialog__close{
    color:#292929;
  }
  .myDialog .el-dialog__footer{
    padding: 0;
  }
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  .myDialog ::-webkit-scrollbar
  {   padding-right: 10px;
    width: 10px;  /*滚动条宽度*/
    height: 10px;  /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/
  //  .myDialog ::-webkit-scrollbar-track
  // {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  //     border-radius: 10px;  /*滚动条的背景区域的圆角*/
  //     background-color: rgba(233, 238, 240, 0.014);/*滚动条的背景颜色*/
  // }

  /*定义滑块 内阴影+圆角*/
  .myDialog ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;  /*滚动条的圆角*/
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #39a6f0;  /*滚动条的背景颜色*/
  }
</style>
