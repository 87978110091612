<template>
<el-drawer
     title="密钥管理"
     :append-to-body="true"
     :visible.sync="options.distributeDrawer"
     custom-class="head_drawer"
     size="50%"
     >
      <div class="secExplain">
        <span style="width:20px"></span>
        <span>标注平台API密钥AccessKey，
        在您调用标注平台API时用于生成请求签名。此密钥是标注平台API请求的重要凭证，
        可以操作您名下所有标注资源，为了保障您的服务和标注资源安全，请妥善保管！</span>
      </div>
      <div class="accessKey">
        <el-form ref="ruleForm" class="drawer_content">
          <el-form-item label="AccessKey" prop="dataset_name">
            <el-input
          readonly
          v-model="accessKey">
            <svg  @click="copyText(accessKey)" slot="suffix" class="icon el-input__icon" aria-hidden="true" style="width:13px;height: 100%;margin-right: 5px;cursor: pointer;">
                <use xlink:href="#iconfuzhi"></use>
            </svg>
        </el-input>
          </el-form-item>
        </el-form>
        
      </div>
</el-drawer>
   
</template>
<script>
  // import Head from '@/components/Head.vue'

  import '@/js/iconfont.js';
  import Ajax from '@/api/pm/taskManage.js';
  // import ITable from '@/components/ITable.vue';
  export default {
    name: "secret-key",
      // components: {
      //   Head,
      //   ITable
      // },
    data () {
      return {
        secretKey: '',
        accessKey: '',
        title1: '密钥管理',
      };
    },
    props: ['options'],
    mounted () {
      this.getSecretKey();
    },
    methods: {
      getSecretKey () {
        let vm = this;
          // 如果开放平台上线的时候给了真正的密钥管理接口时替换调secretKeyList
        Ajax.getApiAccess().then(function (res) {
          if (res.code == 0) {
            vm.secretKey = res.data.secretKey;
            vm.accessKey = res.data.accessKey;
          } else {
            vm.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        });
      },
      copyText (accessKey) {
        let _this = this;
        _this.$copyText(accessKey).then(function (e) {
          _this.$message({
            message: '复制AccessKey成功',
            type: 'success',
            duration: 1000,
            offset: 60
          });
        }, function (e) {
          alert('Can not copy', e.ds_id);
        });
      },
    }
  };
</script>

<style lang="scss">
.head_drawer{
  .el-drawer__body{
    .secExplain{
      margin-bottom: 15px;
      padding: 15px;
      width:100%;
      border-radius: 3px;
      background:#FFF5E9;
      border:1px solid #FFE0B9;
      span{
        font-size: 14px;
        color: #FF930B;
      }
    }
  }
}
</style>

