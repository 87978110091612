export default {
  bind (el, binding, vnode) {
    el.handleInput = () => {
      const newValue = el.children[0].value.replace(/\D/g, ''); // 移除非数字字符（除数字以外的字符）
      el.children[0].value = newValue; // 更新内部输入框的值

      vnode.componentInstance.$emit('input', newValue); // 触发 v-model 绑定的更新
    };

    el.addEventListener('input', el.handleInput);
  },
  unbind (el) {
    el.removeEventListener('input', el.handleInput);
  }
};