import fetch from '../../utils/service';
export default {
  //pm任务管理列表
  getTaskList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-task-manage`,
      data
    });
  },
  deleteTaskList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/delete-taskinfo`,
      data
    });
  },
  
  // pm获取任务类型
  getTaskTypeList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-types`,
      data
    });
  },
  // pm复制任务
  copyTask (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/copy-task`,
      data
    });
  },
  // pm上线任务下线任务
  onlineTask (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/line-status`,
      data
    });
  },
  // pm获取培训文档和公共字段
  getTaskDesc (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-desc`,
      data
    });
  },
  // pm数据详情接口
  getDetailData (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-status`,
      data
    });
  },
  // pm用户考核接口
  getUserAssessmentDataList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/user-train`,
      data
    });
  },
  // pm用户培训考核按钮和查看按钮接口
  getTrainTaskBtn (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-train-task-btn`,
      data
    });
  },
  // pm自定义脚本导出的保存
  saveSetCommand (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/export-set-command`,
      data
    });
  },
  // pm导出数据类型
  getExportDataType (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-export-type`,
      data
    });
  },
  // pm导出数据列表
  getExportDataList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-export-data-list`,
      data
    });
  },
  // pm下载数据时给后台打log
  writeLog (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/write-log`,
      data
    });
  },
  // pm导出数据 data_type task_key必须， type<O 需要三个参数：export_id;type > 0 (type =10 || 11) 参数 pn
  exportData (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/export-data`,
      data
    });
  },
  // pm设置为质检员
  setInspector (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/set-inspector`,
      data
    });
  },
  // pm质检员列表
  getQcDataList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-inspector-statistics`,
      data
    });
  },
  // pm获取质检员
  getInspector (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-inspector`,
      data
    });
  },
  //队员user任务管理列表判断是标注任务还是标注任务审核任务都有
  isChecker (data) {
    return fetch({
      method: 'post',
      url: `/user-task/is-checker`,
      data
    });
  },
  //队员user任务管理列表
  getUserTaskList (data) {
    return fetch({
      method: 'post',
      url: `/user-task/checker-task-manage`,
      data
    });
  },
  // 队员user获取培训文档和公共字段
  getUserTaskDesc (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-checker-task-info`,
      data
    });
  },
  // 队员user标注任务数据详情接口
  userMarkDetailData (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-checker-tag-task-status`,
      data
    });
  },
  // 标注员获取题列表
  getMemPackageTask (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-mem-package-task`,
      data
    });
  },
  // 队员user标注任务考核接口
  userGetTask (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-task`,
      data
    });
  },
  // 队员user审核任务审核接口
  userGetCheckTask (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-check-task`,
      data
    });
  },
  // 队员user标注任务的题包列表接口
  userMarkPackageTask (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-task-package-list`,
      data
    });
  },
  // 队员user放弃整题接口
  packageAbandon (data) {
    return fetch({
      method: 'post',
      url: `/user-task/package-abandon`,
      data
    });
  },
  // 队员user整题驳回（审核员审核任务）
  refusedFree (data) {
    return fetch({
      method: 'post',
      url: `/user-task/refuse-task`,
      data
    });
  },
  // 队员user整题通过（审核员审核任务）
  passFree (data) {
    return fetch({
      method: 'post',
      url: `/user-task/pass-free`,
      data
    });
  },
  // 队员user审核任务的数据详情接口
  userCheckDetailData (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-checker-check-task-status`,
      data
    });
  },
  // 队员user审核任务的数据详情接口
  getCheckerPackageTask (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-checker-package-task`,
      data
    });
  },
  // 队员user审核任务的标注员列表
  getMarkUserList (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-mark-user-list`,
      data
    });
  },
  // 队员user审核任务的包列表
  packageTaskListCheck (data) {
    return fetch({
      method: 'post',
      url: `/user-task/package-task-list-check`,
      data
    });
  },
  // 质检员inspector任务列表
  inspectorTaskList (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/inspector-task-manage`,
      data
    });
  },
  // 质检员inspector培训文档接口
  inspectorTaskInfo (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-task-info`,
      data
    });
  },
  // 质检员inspector开始质检按钮接口
  getInspectorBtnInfo (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-start-inspection-btn-info`,
      data
    });
  },
  // 质检员inspector培训考核接口
  getInspectorExamine (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/user-train`,
      data
    });
  },
  // 质检员inspector培训考核和查看按钮点击接口
  getInspectorExamineBtn (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-train-task-btn`,
      data
    });
  },
  // 质检员inspector状态及数量
  getInspectorStatusNum (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-task-status-num`,
      data
    });
  },
  // 质检员inspector数据详情列表接口
  getInspectorDetailList (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-task-status`,
      data
    });
  },
  // 质检员inspector数据详情列表接口
  getInspectorPackageTask (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-inspector-package-task`,
      data
    });
  },
  // 质检员inspector数据详情通过按钮接口
  getInspectorPassBtn (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/pass-task`,
      data
    });
  },
  // 质检员inspector数据详情驳回按钮接口
  getInspectorRefuseBtn (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/refuse-task`,
      data
    });
  },
  // 质检员inspector详情列表中开始质检接口
  getInspectorPackageList (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/package-task-list`,
      data
    });
  },
  // 质检员inspector点击详细按钮接口
  getDoTaskPackage (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/do-task-package`,
      data
    });
  },
  // 质检员inspector文件名称列表
  getDoTaskByPic (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/get-task-data-list-by-pic`,
      data
    });
  },
  // 质检员inspector文件名称列表的驳回接口
  getDenyTask (data) {
    return fetch({
      method: 'post',
      url: `/inspector-task/deny-task`,
      data
    });
  },
  // 队长captain任务列表
  getChairmanTaskList (data) {
    return fetch({
      method: 'post',
      url: `/chairman-task/index`,
      data
    });
  },
  // 队长captain任务设置审核员页面
  getCheckerList (data) {
    return fetch({
      method: 'post',
      url: `/chairman-task/checker-list`,
      data
    });
  },
  // 队长captain任务设置审核员按钮
  setChecker (data) {
    return fetch({
      method: 'post',
      url: `/chairman-task/set-checker`,
      data
    });
  },
  // 获取用户个人信息
  getUserInfo (data) {
    return fetch({
      method: 'post',
      url: `/user/get-user-info`,
      data
    });
  },
  // 获取密钥
  getApiAccess () {
    return fetch({
      method: 'get',
      url: `/v2/users/access`,
    });
  },
  //用户角色
  getUserRole (data) {
    return fetch({
      method: 'post',
      url: `/v2/users/info`,
      data
    });
  },
  //更新个人信息
  saveUserInfo (data) {
    return fetch({
      method: 'post',
      url: `/user/save-user-info`,
      data
    });
  },
  //pm绩效考核详情
  getPmAchievementsDetails (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-achievements-details`,
      data
    });
  },
  //pm统计列表-审核团队层
  getcheckGuildList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/check-guild-list`,
      data
    });
  },
  //pm统计列表-审核队员层
  getCheckMemberList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/check-member-list`,
      data
    });
  },
  //pm统计列表-质检
  getInspectMemberList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/inspect-member-list`,
      data
    });
  },
  //pm绩效获取任务工具
  getTaskConfTool (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-conf-tool`,
      data
    });
  },
  getTaskDayStatDate (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-day-stat-date`,
      data
    });
  },
  // 导出任务列表
  getGuildSettlementExportList (data) {
    return fetch({
      method: 'post',
      url: `/task/guild-settlement-export-list`,
      data
    });
  },
  //任务统计
  guildLabelGuildList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/label-guild-list`,
      data
    });
  },
  //标注信息 成员层面列表 Pm、质检员、队长、标注员
  getLabelMemberList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/label-member-list`,
      data
    });
  },
  //pm包列表详情
  getPmPackageList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-package-list`,
      data
    });
  },

  getPackageType (data) {
    return fetch({
      method: 'post',
      url: `/task/get-package-type`,
      data
    });
  },
  setPreSelect (data) {
    return fetch({
      method: 'post',
      url: `/task/set-pre-select`,
      data
    });
  },
  // 获取词库列表
  getLibraryList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-entities-manage`,
      data
    });
  },
  // 新建保存词库
  saveEntities (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/save-pm-entities`,
      data
    });
  },
  // 实体内容列表接口
  getEntitiesList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/entities-record-manage`,
      data
    });
  },
  // 删除实体接口
  deleteEntities (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/delete-entities-record`,
      data
    });
  },
  // 获取上传实体列表接口
  getUploadEntitiesList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-upload-entities-list`,
      data
    });
  },
  // 验收账号列表接口
  getCustomerList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-customer-manage`,
      data
    });
  },
  // 验收账号添加修改
  addCustomer (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-customer-save`,
      data
    });
  },
  // 验收邮箱是否存在验证
  customerEmailCheck (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/check-customer-exists`,
      data
    });
  },
  // PM-用户 关系绑定
  pmrRelation (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-user-relation-binding`,
      data
    });
  },
  // PM 验收关联任务列表
  customerTaskList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/customer-task-list`,
      data
    });
  },
  // PM 验收员任务 修改确认时间
  customerExprtime (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/ajax-alter-customer-task-exprtime`,
      data
    });
  },
  // PM 移除验收员关联任务
  customerDelete (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/remove-customer-task`,
      data
    });
  },
  // PM 添加验收员关联任务
  customerAdd (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/distribute-customer-task`,
      data
    });
  },
  // PM 任务关联验收员列表
  taskCustomerList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/task-customer-list`,
      data
    });
  },
  // PM 任务分配验收员
  customerToTask (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/distribute-task-customer`,
      data
    });
  },
  // 验收账号列表接口
  getInspectorList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-inspector-manage`,
      data
    });
  },
  //验收账号添加修改
  addInspector (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/pm-inspector-save`,
      data
    });
  },
  //导出统计记录列表
  getExportStateList (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/export-stat-list`,
      data
    });
  },
  //导出任务状态映射
  getTaskStatusMap (data) {
    return fetch({
      method: 'post',
      url: `/task-stat/get-task-status-map`,
      data
    });
  },
  //获取导出脚本列表
  getExportScriptList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-export-script-list`,
      data
    });
  },
  //设置导出脚本状态（启用、禁用）
  setExportScriptStatus (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/set-export-script-status`,
      data
    });
  },
  //PM获取待质检题包数量
  getWaitSpotCheckNum (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-wait-spot-check-num`,
      data
    });
  },
  //PM批量修改待质检到已驳回或待验收
  batchAlterTaskStatus (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/batch-alter-task-status`,
      data
    });
  },
  //质检抽检列表
  getInspectorDataList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-spot-check-pack-list`,
      data
    });
  },
  //获取质检员待抽检题包数量
  getWaitSpotCheckNum (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-wait-spot-check-num`,
      data
    });
  },
  //获取标注员列表
  getMember (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-member`,
      data
    });
  },
  //创建抽检包
  createSpotCheckPack (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/create-spot-check-pack`,
      data
    });
  },
  //质检员终止抽检包
  stopSpotCheck (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/stop-spot-check`,
      data
    });
  },
  //质检员完成抽检包
  complateSpotCheck (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/complete-spot-check`,
      data
    });
  },
  //获取质检抽检包详情-包列表
  getSpotCheckTaskPackageList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-spot-check-task-package-list`,
      data
    });
  },
  //获取质检抽检包详情-题列表
  getSpotTaskList (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-spot-task-list`,
      data
    });
  },
  //导出质检员抽检报告
  exportSpotCheckReport (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/export-spot-check-report`,
      data
    });
  },
  //获取质检员批量修改题包数量
  getBatchModifyNum (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-batch-modify-num`,
      data
    });
  },
  //质检员批量操作
  BatchModify (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/batch-modify`,
      data
    });
  },
  //标注平台获取题列表
  getAllTaskList (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-task-list`,
      data
    });
  },
  //标注平台获取包列表
  getAllPackageList (data) {
    return fetch({
      method: 'post',
      url: `/user-task/get-package-list`,
      data
    });
  },
  //获取题流转记录
  getTaskLog (data) {
    return fetch({
      method: 'post',
      url: `/task/get-task-log`,
      data
    });
  },        
};
