
export default {
  numFlag: state => state.numFlag, 
  errorLogs: state => state.errorLog.logs,
  bg_img: state => state.bg_img,
  bg_imgs: state => state.bg_imgs,
  pointcloud: state => state.pointcloud,
  project_info: state => state.project_info,
  includes (state) {
    return state.includes;
  }
};
