<template>
  <div class="tm-modifyForm">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="75px" class="demo-ruleForm">
      <el-form-item label="昵称" prop="nick_name">
        <el-input v-model="ruleForm.nick_name"></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="old_password">
        <el-input style="position: fixed;bottom: -9999px;" type="password"></el-input>
        <el-input type="password" show-password v-model="ruleForm.old_password" autocomplete="off" placeholder="请输入原密码"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="new_password">
        <el-input type="password" show-password v-model="ruleForm.new_password" autocomplete="off" placeholder="密码不少于8位，包含大小写字母及数字"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" show-password v-model="ruleForm.checkPass" autocomplete="off" placeholder="请再次输入密码"></el-input>
      </el-form-item>
      <el-form-item label-width="0" style="text-align: center">
        <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">确 认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import Ajax from '@/api/pm/taskManage.js';
  import md5 from 'js-md5';
  export default {
    name: "ModifyForm",
    props: ['options'],
    data () {
      var validatePass2 = (rule, value, callback) => {
        if (value === '' && this.ruleForm.new_password != '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.new_password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          nick_name: '',
          old_password: '',
          new_password: '',
          checkPass: ''
        },
        rules: {
          nick_name: [
            { required: true, message: '请输入昵称', trigger: 'blur' },
          ],
          old_password: [
            { required: false, message: '请输入原密码', trigger: 'blur' },
          ],
          new_password: [
            { required: false, message: '请输入密码', trigger: 'blur' },
            { min: 8, message: '请输入不少于8位密码', trigger: 'blur' },
            { pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/, message: '必须包含数字和英文大小写' }
          ],
          checkPass: [
            { required: false, validator: validatePass2, trigger: 'blur' }
          ],
        },
      };
    },
    mounted () {
      let vm = this;
      vm.ruleForm.nick_name = this.options.data.nickname;
    },
    methods: {
      submitForm (formName) {
        let vm = this;
        vm.$refs[formName].validate((valid) => {
          if (valid) {
            if (vm.ruleForm.new_password != '' && vm.ruleForm.old_password == '') {
              vm.$message({
                message: '修改密码请输入原密码',
                type: 'warning',
                duration: 2000,
                offset: 60
              });
              return;
            }
            Ajax.saveUserInfo({
              nickname: vm.ruleForm.nick_name,
              old_password: md5(vm.ruleForm.old_password),
              new_password: md5(vm.ruleForm.new_password),
            }).then(function (res) {
              if (res.code == 0) {
                vm.$message({
                  message: '个人信息修改成功',
                  type: 'success',
                  duration: 2000,
                  offset: 60
                });
                vm.options.visible = false;
                window.location.href = window.location.origin;
              } else {
                vm.$message({
                  message: res.msg,
                  type: 'warning',
                  duration: 2000,
                  offset: 60
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      resetForm (formName) {
        this.$refs[formName].resetFields();
        this.options.visible = false;
      },

    }
  };
</script>

<style lang="scss">
  .tm-modifyForm{

  }
</style>
