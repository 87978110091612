// 接口请求
import axios from "axios";
// import qs from 'qs';
import NProgress from "nprogress";
import utils from "./utils";
import { Loading, Message } from "element-ui";
let baseURL = import.meta.env.VITE_API_HOST;
let api = "/viewapi";
// process.env.NODE_ENV !== 'development' ? baseURL = process.env.BASE_API : api = '/api';
let transformationUrl = function (parameter) {
  let keys = Object.keys(parameter);
  /* 这里就是把json变成url形式，并进行encode */
  return keys.map((name) => `${name}=${parameter[name]}`).join("&");
  // return encodeURIComponent(keys.map(name => `${name}=${parameter[name]}`).join('&'));
};
export default function (opt) {
  let curApi = `${opt.url}`;
  if (curApi.slice(1, 3) == "v2") {
    api = "";
  } else {
    api = "/viewapi";
  }
  let ContentType = "application/json;charset=UTF-8";
  if (opt.ContentType === "form") {
    ContentType = "application/x-www-form-urlencoded";
  } else if (opt.ContentType === "form-data") {
    ContentType = "multipart/form-data";
  }
  var dataList = {
    method: opt.method,
    url: `${api}${opt.url}`,
    timeout: 100000,
    baseURL: baseURL,
    headers: {
      "Content-Type": ContentType,
      time: new Date().getTime(),
      "access-key": localStorage.getItem("token")
        ? localStorage.getItem("token").replace(/"/g, "")
        : "",
    },
  };
  if (opt.method) {
    if (opt.method.toLowerCase() == "get") {
      if (opt.data) {
        if (opt.data.split_class == "=") {
          delete opt.data.split_class;
          dataList.url = `${dataList.url}${
            dataList.url.indexOf("?") > -1 ? "&" : "?"
          }${transformationUrl(opt.data)}`;
        } else {
          delete opt.data.split_class;
        }
      }

      // opt.ContentType==='form' ? dataList.data = qs.stringify(opt.data) : dataList.data = opt.data;
    } else if (opt.method.toLowerCase() == "delete") {
      if (opt.data && opt.data.project_key) {
        delete opt.data.project_key;
      }
      dataList.data = opt.data;
    } else if (opt.method.toLowerCase() == "put") {
      // delete opt.data.project_key;
      dataList.data = opt.data;
    } else {
      dataList.data = opt.data;
    }
  }
  // alert(JSON.stringify(dataList));
  return new Promise((resolve, reject) => {
    if (ContentType == "form-data") {
      var instance = axios.create({
        timeout: 10000,
        baseURL: baseURL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Vue.config.productionTip = false;
      Vue.prototype.instance = instance;
      instance.post(dataList.url, dataList.data).then(function (res) {});
      // axios.post(dataList.url, dataList.data, {
      //   'Content-Type': 'multipart/form-data'
      // }).then(res => {

      // })
    } else {
      axios(dataList)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error.data);
        });
    }
  });
}
let loadingInstance = null;
// 发送请求前处理数据
axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    // let noLoadingUrl = ['/viewapi/automation/get-upload-status', '/viewapi/pm-task/get-export-data-list', '/viewapi/pm-task/get-upload-list'];

    if (
      config.url.indexOf("http") == -1 &&
      config.url.indexOf("hideloading") == -1 &&
      config.url != "/viewapi/automation/get-upload-status" &&
      config.url != "/viewapi/pm-task/get-export-data-list " &&
      config.url != "/viewapi/pm-task/get-upload-list" &&
      config.url.indexOf("/v2/monitors/screen") == -1
    ) {
      loadingInstance = Loading.service({
        lock: true,
        text: "拼命加载中",
        spinner: "el-icon-loading",
        background: "rgba(277, 277, 255, 0.5)",
      });
    }
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

// 返回响应请求后处理数据
axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    if (res.data.code == 400 || res.data.code == "UserLoginError") {
      setTimeout(() => {
        window.location.href = `/v/login`;
        localStorage.removeItem("token");
      }, 500);
      // window.location.href=`http://a.home.youshu.com:8081/login`;
    }
    if (res.data.code == 404) {
      window.location.href = `${import.meta.env.VITE_API_HOST}/error/404`;
    }
    if (
      res.config.url != "/viewapi/automation/upload-compress" &&
      res.config.url.indexOf("/v2/monitors/screen") == -1
    ) {
      loadingInstance.close();
    } else {
      if (res.config.data && res.config.data.get("upload_status") == "join") {
        loadingInstance.close();
      }
    }
    return res;
  },
  (error) => {
    loadingInstance.close();
    NProgress.done();
    let errorCode = error.response.code;
    if ([404, 405, 500, 504].includes(errorCode)) {
      Message.warning({ message: "服务器内部错误" });
    }
    return Promise.reject(error.response);
  }
);
