<template>
  <div class="header" v-if="headShow">
    <a href="/">
      <img v-if="logoPath != ''" class="logo" :src="logoPath" alt="">
      <span v-if="logoPath == ''" class="logo">{{ logoTitle }}</span>
    </a>
    <div class="nav">
      <el-tabs v-model="activeName" @tab-click="goTabs" :before-leave='leaveTab'>
        <el-tab-pane @click="goLink(item.route)" v-for="(item, index) in menuList" :key="index" :label="item.title"
          :name="item.route"></el-tab-pane>
      </el-tabs>
      <el-dropdown trigger="click" v-if="userInfo.nickname">
        <div class="el-dropdown-link">
          <div>{{ `${userInfo.user_name}` }}
            <svg class="icon" aria-hidden="true" style="width:7px;vertical-align: -4px">
              <use xlink:href="#iconxialasanjiao"></use>
            </svg>
          </div>
          <div>ID:{{ userInfo.user_id }}</div>
          <div class="headerLink"></div>
        </div>
        <el-dropdown-menu slot="dropdown" class="headDropdown">
          <el-dropdown-item :disabled=true>{{ userInfo.nickname }}</el-dropdown-item>
          <el-dropdown-item :disabled=true>
            <div>{{ `${userInfo.user_name}` }}</div>
            <div>ID:{{ userInfo.user_id }}</div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="editPwd">修改密码</el-dropdown-item>
          <!-- 密钥管理先注释掉等开放平台上线的时候，在一起上，请不要删除哦 -->
          <el-dropdown-item v-if="isShowmy" @click.native="goSecretKey">密钥管理</el-dropdown-item>
          <el-dropdown-item @click.native="logout()">退出
            <svg class="icon" aria-hidden="true" style="margin-top: 13px; float:right">
              <use xlink:href="#icontuichudenglu"></use>
            </svg></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <router-link v-if="currentHost.indexOf('label-yunzhi.testin.cn') > -1" to="/dataService-view"
        style="float: right;" target="_blank">
        <el-tooltip class="item" effect="dark" content="数据大屏" placement="bottom-start">
          <svg t="1673519476859" class="icon" viewBox="0 0 1024 1024" version="1.1"
            style="height: 24px; width: revert;margin-top: 12px;margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
            p-id="1232" xmlns:xlink="http://www.w3.org/1999/xlink" width="64" height="64">
            <path
              d="M85.333333 213.333333a42.666667 42.666667 0 0 1 42.666667-42.666666h768a42.666667 42.666667 0 0 1 42.666667 42.666666v512a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667V213.333333z m85.333334 42.666667v426.666667h682.666666V256H170.666667zM298.666667 832h426.666666v85.333333H298.666667v-85.333333z"
              fill="#17202d" p-id="1233" data-spm-anchor-id="a313x.7781069.0.i9" class="selected"></path>
            <path
              d="M417.834667 375.168a42.666667 42.666667 0 0 1 60.330666 0L576 473.002667l85.333333-85.333334L721.664 448l-115.498667 115.498667a42.666667 42.666667 0 0 1-60.330666 0L448 465.664l-85.333333 85.333333L302.336 490.666667l115.498667-115.498667z"
              fill="#0155ff" p-id="1234" data-spm-anchor-id="a313x.7781069.0.i6" class=""></path>
          </svg>
        </el-tooltip>
      </router-link>
    </div>
    <MDialog :options="dialogEditOptions" class="modifyForm">
      <ModifyForm slot="dialogContent" v-if="dialogEditOptions.visible" @updateedit="updateedit"
        :options="dialogEditOptions"></ModifyForm>
    </MDialog>
    <secretKey v-if="drawerOptions.distributeDrawer" :options="drawerOptions"></secretKey>
    <editPwd v-if="drawerPwdOptions.distributeDrawer" :options="drawerPwdOptions"></editPwd>
  </div>
</template>

<script>
import Ajax from '@/api/create.js';
import Ajax1 from '@/api/attendanceData.js';
import MDialog from '@/components/dialog/dialog.vue';
import ModifyForm from './head/ModifyForm.vue';
import secretKey from './head/secretKey.vue';
import editPwd from './head/editPwd.vue';
export default {
  inject: ['reload'],
  components: {
    MDialog,
    ModifyForm,
    secretKey,
    editPwd
  },
  data () {
    return {
      drawerOptions: {
        distributeDrawer: false,
        data: {},
      },
      logoPath: "",
      logoTitle: "",
      drawerPwdOptions: {
        distributeDrawer: false,
        data: {},
      },
      isShowmy: false,
      activeName: '', //导航选中
      currentHost: window.location.href,
      isShowClockTime: false,
      menuList: [],
      clock_time: '',
      clock_str: '',
      h: '',
      m: '',
      s: '',
      time: null,
      times: '', // 统计共多少时间
      work_start: '', //打卡时间
      state: '',
      headShow: false, //判断头部是否显隐
      ruleForm: {
        userName: '',
        passWord: '',
        checkPass: ''
      },
      dialogEditOptions: {
        visible: false,
        title: "修改个人信息",
        data: {}
      },
    };
  },
  props: ['userInfo'],
  watch: {
    'userInfo': function (a, b) {
      this.getNav();
    },
    '$route' (to, from) { //监听路由是否变化
      this.getNav();
    }
  },
  mounted () {
    let roleId = localStorage.getItem('role');
    this.getNav();
  },
  created () {
    this.setConfig();
  },
  methods: {
    logout () {
      const vm = this;
      Ajax.userLogout({}).then((res) => {
        if (res.code == '') {
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
          vm.$router.push('/login');
        } else {
          vm.$message({
            message: res.msg,
            type: 'error',
            duration: 2000,
            offset: 60
          });
        }
      });

    },
    goPersonalMsg () {
      this.dialogEditOptions.visible = true;
      this.dialogEditOptions.data = { nickname: this.userInfo.nickname };
    },
    goSecretKey () {
      let vm = this;
      vm.drawerOptions.distributeDrawer = true;
    },
    editPwd () {
      let vm = this;
      vm.drawerPwdOptions.distributeDrawer = true;
      this.drawerPwdOptions.data = { nickname: this.userInfo.nickname };
    },
    updateedit (val) {

    },
    handleSelect (key, keyPath) { },
    leaveTab (activeName, oldActiveName) {
      if (activeName == '/dev-tool') {
        return false;
      }
    },
    goTabs (tab, event) {
      this.$store.commit('changeIncludes', []);
      this.activeName = tab.name;
      if (tab.name == '/dev-tool') {
        window.open("/docs/platform/");
      } else {
        if (tab.name == this.$route.path || this.$route.path.indexOf(tab.name) > -1) {
          this.reload();
        } else {
          this.$router.push({
            path: tab.name,
          });
        }
      }

    },
    goLink (route) {
      this.getNav();
    },
    getNav () {
      let _this = this;
      // 判断头部是否展示
      // localStorage.setItem('userInfo',JSON.stringify( _this.userInfo));
      if (_this.$route.path != '/wechatLogin-status' && _this.$route.path != '/login' && _this.$route.path != '/sign-up' && _this.$route.path != '/dataService-view') {
        _this.headShow = true;
      } else {
        _this.headShow = false;
      }
      //获取userInfo
      if (_this.userInfo) {
        if (_this.userInfo.role_id == 2) {//pm角色
          _this.menuList = [
            { title: "项目管理", route: "/project-list" },
            { title: "数据集管理", route: "/data-station/dataset" },
            { title: "团队管理", route: "/user-manage-index" },
            { title: "开发者工具", route: "/dev-tool" },
            { title: "模型管理", route: "/model-manage-index" },
          ];
        } else if (_this.userInfo.role_id == 1) { // 管理员
          _this.menuList = [
            { title: "项目管理", route: "/project-list" },
            { title: "数据集管理", route: "/data-station/dataset" },
            { title: "团队管理", route: "/user-manage-index" },
            { title: "开发者工具", route: "/dev-tool" },
            { title: "系统数据", route: "/monitor/status" },
            { title: "模型管理", route: "/model-manage-index" },
          ];
        } else if (_this.userInfo.role_id == 0) { //用户
          if (_this.userInfo.team_admin == 1) {
            _this.menuList = [
              { title: "项目管理", route: "/project-list" },
              { title: "团队管理", route: "/user-manage-index/projectTeamManage" },
            ];
          } else {
            _this.menuList = [
              { title: "项目管理", route: "/project-list" },
            ];
          }


        }

        //角色判断当前tab路由
        let userRoute = _this.$route.path.substring(0, _this.$route.path.lastIndexOf('/')) == '/user-manage-index';
        // console.log(userRoute);
        if (userRoute) {
          // if (_this.$route.path == '/user-manage-index/userManage') {
          _this.activeName = '/user-manage-index';
          // } else {
          //   _this.activeName = '/user-manage-index/projectTeamManage';
          // }
          // if (_this.userInfo.team_admin == 1) {
          //   _this.activeName = '/user-manage-index/projectTeamManage';
          // } else {
          //   _this.activeName = '/user-manage-index';
          // }
        } else if (_this.$route.path.indexOf('monitor') > -1) {
          _this.activeName = '/monitor/status';
        } else if (_this.$route.path.indexOf('data-station') > -1 || _this.$route.path.indexOf('/dataset/') > -1) {
          _this.activeName = '/data-station/dataset';
        } else if (_this.$route.path.indexOf('project-list') > -1 || _this.$route.path.indexOf('project-dataManage') > -1 || _this.$route.path.indexOf('/project/') > -1) {
          _this.activeName = '/project-list';
        } else if (_this.$route.path.indexOf('model-manage-index') > -1) {
          _this.activeName = '/model-manage-index';
        } else {
          if (_this.menuList[0]) {
            _this.activeName = _this.menuList[0].route;
          }
        }
        // 是不是有密钥管理
        if (_this.userInfo.role_id == 2 || _this.userInfo.role_id == 1) { //pm admin
          _this.isShowmy = true;
        } else {
          _this.isShowmy = false;
        }
      }
    },
    setConfig () {
      if (localStorage.getItem('config')) {
        let config = JSON.parse(localStorage.getItem('config'));
        this.logoPath = config.web_logo_path;
        this.logoTitle = config.web_name;
      }
    },
  }
};
</script>

<style lang="scss">
body {
  .headDropdown {
    width: 180px;
    max-height: 204px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px rgba(184, 194, 203, 0.4);
    border-radius: 6px;

    .el-dropdown-menu__item {
      height: 40px;
      font-weight: 700;

      a:hover {
        color: #17202D;
      }

      &:hover {
        color: #17202D;
        background: #F2F4F6;
      }
    }

    li {
      font-size: 13px;
      color: #17202D;
      line-height: 40px;
    }

    li:nth-child(1) {
      font-size: 16px;
      font-weight: 500;
      color: #17202D;
    }

    li:nth-child(2) {
      div:nth-child(1) {
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #17202D;
        line-height: 22px;
      }

      div:nth-child(2) {
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6E798B;
        line-height: 20px;
      }
    }

    li:nth-child(3) {
      margin-top: 6px
    }
  }
}

.header {
  height: 48px;
  line-height: 50px;
  // background: rgba(247, 249, 252, 1);
  // box-shadow: 0px 4px 5px 0px rgba(23, 24, 25, 0.08);
  border-bottom: 1px solid #DCE1E7;
  background: #FFFFFF;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;

  .el-dropdown-menu .el-dropdown-menu__item {
    padding: 0;

    a {
      display: block;
      padding: 0 20px;

      &:hover {
        color: #17202D
      }
    }

  }

  .nav {
    flex-grow: 1;
    margin-left: 100px;
    padding-left: 22px;
    display: flex;
    justify-content: space-between;

    /* position: absolute; */
    /* width: calc(100% - 220px);
      left: 200px;
      top: 0;
      height: 100%; */
    // padding-left:200px;
    box-sizing: border-box;

    .el-tabs {
      display: inline-block;
    }
  }

  .logo {
    height: 30px;
    margin-left: 20px;
    vertical-align: middle;
    border: 0;
  }

  .el-container {
    line-height: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 0;
    width: 1000px;
    margin-left: -500px;
  }

  .el-menu.el-menu--horizontal {
    background: none;
  }

  .fr {
    float: right;
  }

  .el-dropdown {
    margin-top: 4px;
    /* float: right; */
    /* margin-left: 180px; */
    padding: 0 29px 0 10px;
  }

  .el-dropdown-link {
    color: #17202D;
    cursor: pointer;
    font-weight: 400;
    position: relative;

    .headerLink {
      position: absolute;
      top: 7px;
      left: -10px;
      width: 1px;
      background: #EEEEEE;
      height: 24px;
      z-index: 20;
    }

    div:nth-child(1) {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #17202D;
      line-height: 22px;
    }

    div:nth-child(2) {
      height: 20px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6E798B;
      line-height: 18px;
    }
  }

  .el-tabs__nav-wrap::after {
    background-color: none;
  }

  .el-tabs__item {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6E798B;
    line-height: 20px;
  }

  .el-tabs__item.is-active {
    color: #242930;
    font-weight: bold;
  }

  .el-tabs__active-bar {
    display: none;
  }

  a {
    color: #606266;
    cursor: pointer;
    outline: none;
  }

  .el-dropdown-menu__item a {
    color: #606266;
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }

  .clockIn {
    display: inline-block;
    float: right;
    margin-right: 20px;
    font-size: 13px;

    .clockIn-time {
      margin-right: 5px;
    }
  }

  span.logo {
    font-size: 18px;
    font-weight: 400;
    color: #1D8DFE;
    margin-left: 10px;
  }
}
</style>
