<template>
  <el-aside>
    <transition name="el-fade-in-linear">
      <el-menu router :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse"
        background-color="#F7F8FA" text-color="#2A3749" active-text-color="#2A3749" @select="handleSelect" ref="menu">
        <el-menu-item style="height:55px;line-height:55px" v-if="projectList">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconrenwu"></use>
          </svg>
          <span v-if="isCollapse" slot="title">{{ project_info.project_name }}</span>

          <el-dropdown trigger="click">
            <el-tooltip :content="project_info.project_name ? project_info.project_name : project_name"
              :disabled="project_info.project_name && project_info.project_name.replace(/[^\x00-\xff]/g, '00').length <= 18"
              placement="right">
              <span class="el-dropdown-link" style="color:#2A3749">{{
                project_info.project_name
              }}</span>
            </el-tooltip>
            <!-- <span class="el-dropdown-link">
                下拉菜单
            </span> -->
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <svg class="icon" aria-hidden="true"
                  style="margin-right:10px;vertical-align: 0px; height: 14px; width: 14px;">
                  <use xlink:href="#iconrenwu"></use>
                </svg>
                <router-link :to="'/project-list'"
                  style="font-size:14px;color:#17202D">查看全部项目</router-link></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item style="height:55px;line-height:55px"
          v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshujujixiangmumingcheng"></use>
          </svg>
          <span v-if="isCollapse" slot="title">{{ dataset_info.dataset_name }}</span>
          <el-dropdown trigger="click">
            <el-tooltip :content="dataset_info.dataset_name"
              :disabled="dataset_info.dataset_name && dataset_info.dataset_name.replace(/[^\x00-\xff]/g, '00').length <= 18"
              placement="right">
              <span class="el-dropdown-link" style="color:#2A3749">{{
                dataset_info.dataset_name
              }}</span>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <svg class="icon" aria-hidden="true"
                  style="margin-right:10px;vertical-align: 0px; height: 14px; width: 14px;">
                  <use xlink:href="#iconshujujixiangmumingcheng"></use>
                </svg>
                <router-link :to="'/data-station/dataset'"
                  style="font-size:14px;color:#17202D">查看全部数据集</router-link></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <div class="hr"></div>
        <div class="null8"></div>
        <el-menu-item index="/project-list" v-if="$route.path == '/project-list'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconxiangmu"></use>
          </svg>
          <span slot="title">项目管理</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-dataManage`"
          v-if="userRole != 0 && $route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshujuguanli"></use>
          </svg>
          <span slot="title">数据管理</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-taskManage`" v-if="$route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconrenwuguanli"></use>
          </svg>
          <span slot="title">任务管理</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-config`"
          v-if="$route.name == 'projectConfig' && (userRole == 2 || userRole == 1)">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconbiaozhupeizhi"></use>
          </svg>
          <span slot="title">标注配置</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-webHook`"
          v-if="userRole != 0 && $route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconwebhook"></use>
          </svg>
          <span slot="title">WebHook</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-model`"
          v-if="userRole != 0 && $route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconmoxingfuwu"></use>
          </svg>
          <span slot="title">智能标注</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/project-performanceManage`"
          v-if="$route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icona-bianzu3"></use>
          </svg>
          <span slot="title">绩效管理</span>
        </el-menu-item>
        <el-menu-item :index="`/project/${$route.params.id}/label-count`"
          v-if="(userRole == 2 || userRole == 1) && $route.name == 'projectConfig'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshixiaofenxi"></use>
          </svg>
          <span slot="title">实效分析</span>
        </el-menu-item>
        <el-menu-item index="/user-manage-index/organizationManage"
          v-if="userRole == 1 && ($route.path.substring(0, $route.path.lastIndexOf('/')) == '/user-manage-index' || $route.path == '/user-manage-index')">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconzuzhiguanli"></use>
          </svg>
          <span slot="title">组织管理</span>
        </el-menu-item>
        <el-menu-item index="/user-manage-index/userManage"
          v-if="userRole != 0 && ($route.path.substring(0, $route.path.lastIndexOf('/')) == '/user-manage-index' || $route.path == '/user-manage-index')">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconyonghuguanli"></use>
          </svg>
          <span slot="title">用户管理</span>
        </el-menu-item>
        <el-menu-item index="/user-manage-index/projectTeamManage"
          v-if="$route.path.substring(0, $route.path.lastIndexOf('/')) == '/user-manage-index' || $route.path == '/user-manage-index'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icontuanduiguanli"></use>
          </svg>
          <span slot="title">团队管理</span>
        </el-menu-item>
        <el-menu-item index="/monitor/status"
          v-if="userRole != 0 && ($route.path.substring(0, $route.path.lastIndexOf('/')) == '/monitor' || $route.path == '/monitor')">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconrenwu"></use>
          </svg>
          <span slot="title">系统状态</span>
        </el-menu-item>
        <el-menu-item index="/monitor/server"
          v-if="$route.path.substring(0, $route.path.lastIndexOf('/')) == '/monitor' || $route.path == '/monitor'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconzuzhiguanli"></use>
          </svg>
          <span slot="title">服务器</span>
        </el-menu-item>
        <el-menu-item index="/monitor/stat"
          v-if="$route.path.substring(0, $route.path.lastIndexOf('/')) == '/monitor' || $route.path == '/monitor'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconritongji"></use>
          </svg>
          <span slot="title">天统计</span>
        </el-menu-item>
        <el-menu-item index="/data-station/dataset"
          v-if="$route.path.substring(0, $route.path.lastIndexOf('/')) == '/data-station' || $route.path == '/data-station'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshujuji"></use>
          </svg>
          <span slot="title">数据集管理</span>
        </el-menu-item>
        <el-menu-item :index="`/dataset/${ds_id}/detail`"
          v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshujuxiangqing"></use>
          </svg>
          <span slot="title">数据详情</span>
        </el-menu-item>
        <el-menu-item :index="`/dataset/${ds_id}/record/upload`"
          v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconhuodongjilu1"></use>
          </svg>
          <span slot="title">活动记录</span>
        </el-menu-item>
        <el-menu-item :index="`/dataset/${ds_id}/setup`"
          v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconshezhi3"></use>
          </svg>
          <span slot="title">设置</span>
        </el-menu-item>
        <el-menu-item :index="`/dataset/${ds_id}/bind-project`"
          v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconyijinrubangdingxiangmu"></use>
          </svg>
          <span slot="title">绑定项目</span>
        </el-menu-item>
        <el-menu-item index="/model-manage-index/modelManage"
          v-if="$route.path.substring(0, $route.path.lastIndexOf('/')) == '/model-manage-index' || $route.path == '/model-manage-index'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconmoxingfuwu"></use>
          </svg>
          <span slot="title">模型管理</span>
        </el-menu-item>
        <!-- 跳转到项目详情 -->
        <!-- <el-menu-item v-if="$route.matched[0] && $route.matched[0].name == 'dataset-detail' && dataset_info.project_key" @click="goProject(dataset_info.project_key)">
          <svg class="icon" aria-hidden="true" >
            <use xlink:href="#iconyijinrubangdingxiangmu"></use>
          </svg>
          <span slot="title">进入绑定项目</span>
        </el-menu-item> -->
      </el-menu>
    </transition>

    <div :class="isCollapse ? 'hideMenu' : 'showMenu'" @click="collapseFn()">
      <svg class="icon" aria-hidden="true" style="vertical-align: -2px;margin-right: 7px;">
        <use :xlink:href="isCollapse ? '#iconcaidanzhankai' : '#iconcaidanshouqi'"></use>
      </svg>
    </div>
  </el-aside>

</template>

<script>
import '@/js/iconfont.js';
export default {
  inject: ['reload'],
  data () {
    return {
      activeIndex: '1',
      isCollapse: false,
      project_name: '项目名称',
      projectList: false,
      defaultActive: '',
      ds_id: ''
    };
  },
  computed: {
    project_info () {
      return this.$store.state.project_info;
    },
    dataset_info () {
      return this.$store.state.dataset_info;
    }
  },
  watch: {
    '$route' (to, from) {
      let project_key = this.$route.params.id;
      this.ds_id = this.$route.params.ds_id;
      if (this.$route.name == 'projectConfig') { //侧边栏项目列表
        this.projectList = true;
      } else {
        this.projectList = false;
      }
      if (this.$route.path == '/user-manage-index/teamUser' || (this.$route.path == '/user-manage-index' && this.team_admin == 1)) {
        this.$refs.menu.activeIndex = '/user-manage-index/projectTeamManage';
      } else if (this.$route.path == '/user-manage-index/organizationUser') {
        this.$refs.menu.activeIndex = '/user-manage-index/organizationManage';
      } else if (this.$route.path.indexOf(`/project/${project_key}/project-taskIndex`) > -1 || this.$route.path == `/project/${project_key}/data-reviewReport`) {
        this.$refs.menu.activeIndex = `/project/${project_key}/project-taskManage`;
      } else if (this.$route.path == `/project/${project_key}/webHook-record`) {
        this.$refs.menu.activeIndex = `/project/${project_key}/project-webHook`;
      } else if (this.$route.path == `/project/${project_key}/project-model`) {
        this.$refs.menu.activeIndex = `/project/${project_key}/project-model`;
      } else if (this.$route.path == `/project/${project_key}/project-performanceManage` || this.$route.path == `/project/${project_key}/task-team-detail` || this.$route.path.indexOf('user-label') > -1) {
        this.$refs.menu.activeIndex = `/project/${project_key}/project-performanceManage`;
      }
      else if ((this.$route.path.indexOf('project-dataManage') > -1 || this.$route.path.indexOf('sequence-fileList') > -1 || this.$route.path.indexOf('fileDetail') > -1 || this.$route.path.indexOf('fileView') > -1 || this.$route.path.indexOf('upload-handleList') > -1 || this.$route.path.indexOf('push-handleList') > -1) && this.$route.path.indexOf('/dataset/') == -1) {
        this.$refs.menu.activeIndex = `/project/${project_key}/project-dataManage`;
      }
      else if (this.$route.path == '/monitor/server') {
        this.$refs.menu.activeIndex = '/monitor/server';
      } else if (this.$route.path == '/monitor/status' || this.$route.path == '/monitor') {
        this.$refs.menu.activeIndex = '/monitor/status';
      } else if (this.$route.path == '/data-station/dataset' || this.$route.path == '/data-station') {
        this.$refs.menu.activeIndex = '/data-station/dataset';
      } else if (this.$route.path.indexOf('/dataset/') > -1 && (this.$route.path.indexOf('sequence-fileList') > -1 || this.$route.path.indexOf('fileDetail') > -1 || this.$route.path.indexOf('fileView') > -1)) {
        this.$refs.menu.activeIndex = `/dataset/${this.ds_id}/detail`;
      } else if (this.$route.path.indexOf('label-count') > -1) {
        this.$refs.menu.activeIndex = `/project/${project_key}/label-count`;
      } else if (this.$route.path.indexOf(`dataset/${this.ds_id}/record`) > -1) {
        this.$refs.menu.activeIndex = `/dataset/${this.ds_id}/record/upload`;
      } else {
        this.$refs.menu.activeIndex = this.$route.path;
      }
    }
  },
  created () {
    this.userRole = JSON.parse(localStorage.getItem('userInfo')).role_id;
    this.team_admin = JSON.parse(localStorage.getItem('userInfo')).team_admin;

    this.isCollapse = JSON.parse(localStorage.getItem('isCollapse'));
  },
  mounted () {
    let project_key = this.$route.params.id;
    this.ds_id = this.$route.params.ds_id;
    if (this.$route.name == 'projectConfig') { //侧边栏项目列表
      this.projectList = true;
    } else {
      this.projectList = false;
    }
    if (this.$route.path == '/user-manage-index/teamUser' || (this.$route.path == '/user-manage-index' && this.team_admin == 1)) {
      this.$refs.menu.activeIndex = '/user-manage-index/projectTeamManage';
    } else if (this.$route.path == '/user-manage-index/organizationUser') {
      this.$refs.menu.activeIndex = '/user-manage-index/organizationManage';
    } else if (this.$route.path.indexOf(`/project/${project_key}/project-taskIndex`) > -1 || this.$route.path == `/project/${project_key}/data-reviewReport`) {
      this.$refs.menu.activeIndex = `/project/${project_key}/project-taskManage`;
    }
    else if ((this.$route.path.indexOf('project-dataManage') > -1 || this.$route.path.indexOf('sequence-fileList') > -1 || this.$route.path.indexOf('fileDetail') > -1 || this.$route.path.indexOf('fileView') > -1 || this.$route.path.indexOf('upload-handleList') > -1 || this.$route.path.indexOf('push-handleList') > -1) && this.$route.path.indexOf('/dataset/') == -1) {
      this.$refs.menu.activeIndex = `/project/${project_key}/project-dataManage`;
    }
    else if (this.$route.path == `/project/${project_key}/webHook-record`) {
      this.$refs.menu.activeIndex = `/project/${project_key}/project-webHook`;
    } else if (this.$route.path == `/project/${project_key}/project-model`) {
      this.$refs.menu.activeIndex = `/project/${project_key}/project-model`;
    } else if (this.$route.path == `/project/${project_key}/project-performanceManage` || this.$route.path == `/project/${project_key}/task-team-detail` || this.$route.path.indexOf('user-label') > -1) {
      this.$refs.menu.activeIndex = `/project/${project_key}/project-performanceManage`;
    } else if (this.$route.path == '/monitor/server') {
      this.$refs.menu.activeIndex = '/monitor/server';
    } else if (this.$route.path == '/monitor/status' || this.$route.path == '/monitor') {
      this.$refs.menu.activeIndex = '/monitor/status';
    } else if (this.$route.path == '/data-station/dataset' || this.$route.path == '/data-station') {
      this.$refs.menu.activeIndex = '/data-station/dataset';
    } else if (this.$route.path.indexOf('/dataset/') > -1 && (this.$route.path.indexOf('sequence-fileList') > -1 || this.$route.path.indexOf('fileDetail') > -1 || this.$route.path.indexOf('fileView') > -1)) {
      this.$refs.menu.activeIndex = `/dataset/${this.ds_id}/detail`;
    } else if (this.$route.path.indexOf('label-count') > -1) {
      this.$refs.menu.activeIndex = `/project/${project_key}/label-count`;
    } else if (this.$route.path.indexOf(`dataset/${this.ds_id}/record`) > -1) {
      this.$refs.menu.activeIndex = `/dataset/${this.ds_id}/record/upload`;
    }
    else {
      this.$refs.menu.activeIndex = this.$route.path;
    }
  },
  beforeUpdate () {
    // console.log(this.$route.path.substring(0,this.$route.path.lastIndexOf('/')));
  },
  methods: {
    handleSelect (index, indexPath) {
      let includes = this.$store.state.includes;
      if (index == '/user-manage-index/projectTeamManage') {
        // 团队管理
        this.reload();
      } else if (index == '/user-manage-index/organizationManage') {
        // 组织管理
        this.reload();
      } else if (index == `/project/${this.$route.params.id}/project-taskManage`) {
        // 任务管理
        this.reload();
      }


    },
    collapseFn () {
      this.isCollapse = !this.isCollapse;
      localStorage.setItem('isCollapse', this.isCollapse);
    },
    goProject (peoject_key) {
      this.$router.push({
        path: `/project/${peoject_key}/project-dataManage`
      });
    }
  }
};
</script>

<style lang="scss">
.el-aside {
  width: auto !important;
  height: 100vh;
  // margin-top: 49px;
  background: #DCE1E7;

  .aside-head {
    height: 55px;
    line-height: 55px;
    margin: 0 20px;
    cursor: pointer;
    // border-left: 3px solid transparent;
    border-bottom: 1px solid #DCE1E7;

    i {
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
    }
  }

  .el-menu {
    height: 100%;
    font-size: 14px;
    position: relative;

    .el-dropdown {
      cursor: pointer;

      span {
        white-space: nowrap;
        max-width: 142px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }

    .hr {
      // border-bottom: 1px solid #DCE1E7;
      width: 167px;
      margin: 0 auto;
    }

    .el-dropdown-selfdefine {
      font-weight: 600;
    }

    .el-menu-item {
      display: flex;
      align-items: center;
      font-size: 14px;

      span {
        margin-left: 10px;
      }
    }

    .el-menu-item,
    .el-submenu__title {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;

      // border-left: 3px solid transparent;
      i {
        color: #2A3749;
      }

      .el-tooltip {
        text-align: center;
        left: -2px !important;
        // padding: 0 18px!important;
      }
    }

    .el-menu-item:first-child,
    .el-submenu__title:first-child {
      .el-tooltip {
        // padding: 0!important;
        // padding-left: 16px!important;
      }
    }

    .el-menu-item:first-child:hover {
      background: transparent !important;
      cursor: unset;
    }

    .el-menu-item:not(:first-child):hover,
    .el-submenu__title:not(:first-child):hover {
      background: #E6E9ED !important;
    }

    .el-menu-item.is-active {
      // border-left: 3px solid #496DFF;
      background: #E6E9ED !important;
      font-weight: 600;
      position: relative;

      &::after {
        content: '';
        width: 3px;
        height: 100%;
        background: #496DFF;
        position: absolute;
        left: 0;
        top: 0
      }
    }

  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 220px;

    li:nth-child(1) {

      // margin-bottom:5px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 19px;
        width: 176px;
        border-bottom: 1px solid#DCE1E7;
      }
    }

    // min-height: 400px;
  }

  .el-menu--collapse {
    width: 52px;

    li:nth-child(1) {

      // margin-bottom:5px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 11px;
        width: 30px;
        border-bottom: 1px solid#DCE1E7;
      }
    }

    .el-menu-item {
      // padding-left:17px!important;
      display: flex;
      align-items: center;
    }
  }

  .showMenu {
    position: fixed;
    bottom: 10px;
    left: 190px;
    cursor: pointer;

    i {
      float: right;
      font-size: 20px;
    }
  }

  .hideMenu {
    position: fixed;
    bottom: 10px;
    left: 15px;
    cursor: pointer;

    i {
      float: right;
      font-size: 20px;
    }
  }

  .null8 {
    height: 8px;
  }
}
</style>
