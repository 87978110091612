<template>
  <div v-loading="loading" class="d-micro" ref="micro"></div>
</template>
<script>

import { DarkIframeSdk } from './darkIframeSdk'
import { MICRO_KEYS } from './microKeys';

export default {
  name: 'd-micro',
  props: ['url', 'uuid'],
  data() {
    return {
      loading: true
    };
  },
  created() {
  },
  components: {
  },
  watch: {

  },
  mounted() {
    this.$nextTick(() => {
      const { uuid, url } = this
      const ele = this.$refs.micro
      this.darkIframe = new DarkIframeSdk({
        uuid,
        ele,
        url,
        onMessage: this.handleMessage
      })
    })

  },
  destroyed() {
    this.darkIframe.destroyed()
  },
  methods: {
    sendMsg(type, data) {
      this.darkIframe.sendMsg(type, data)
    },
    handleMessage(d) {
      this.loading = false
      const { type, } = d
      switch (type) {
        case MICRO_KEYS.S_ON_LOAD:
          this.handleSendBootstrap()
          break
        case MICRO_KEYS.S_LOGIN_OUT:
          window.location.href = `/v/login`;
          localStorage.removeItem('token');
          break
        default:
          this.$emit('onMessage', d);
          break
      }
    },
    handleSendBootstrap() {
      const token = localStorage.getItem('token')
      this.sendMsg(MICRO_KEYS.O_BOOTSTARP, { token })
    }
  }
};
</script>

<style lang="scss">
.d-micro {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
