import fetch from '../utils/service';
export default {
   // 组织列表
  getOrganitionList (data) {
    return fetch({
      method: 'GET',
      url: '/v2/organizes',
      data
    });
  },
  //新建组织
  creatOrganition (data) {
    return fetch({
      method: 'POST',
      url: '/v2/organizes',
      data
    });
  },
  //修改组织信息
  changeOrganition (data) {
    return fetch({
      method: 'PUT',
      url: `/v2/organizes/${data.organize_id}`,
      data
    });
  },
  // 用户列表
  getUserList (data) {
    return fetch({
      method: 'GET',
      url: '/v2/users',
      data
    });
  },
  //新建用户
  creatUser (data) {
    return fetch({
      method: 'POST',
      url: '/v2/users',
      data
    });
  },
  //修改用户信息
  changeUser (data) {
    return fetch({
      method: 'PUT',
      url: `/v2/users/${data.user_id}`,
      data
    });
  },
  //团队列表
  getTeamList (data) {
    return fetch({
      method: 'GET',
      url: '/v2/guilds',
      data
    });
  },
  //新增团队
  creatTeam (data) {
    return fetch({
      method: 'POST',
      url: '/v2/guilds',
      data
    });
  },
  //修改团队
  changeTeam (data) {
    return fetch({
      method: 'PUT',
      url: `/v2/guilds/${data.gid}`,
      data
    });
  },
};