// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/icon.css'
import "./styles/theme/index.css";
import "./styles/base.css";
import ElementUI from "element-ui";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import x2js from "x2js"; //xml数据处理插件
Vue.prototype.$x2js = new x2js(); //创建x2js对象，挂到vue原型上
import watermark from "./js/watermark.js";
import { createMicroApp } from "./shared/micro/index.js";
Vue.prototype.$watermark = watermark;

import filters from "./utils/filters.js";

import "./plugins/index";

import global from "./global/index.js";
Vue.prototype.GLOBAL = global;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
import store from "./store";
//解决
import "default-passive-events";
import dataV from "@jiaminghi/data-view";
Vue.use(dataV);
//优化el-message重复提示
import { Message } from "element-ui";

import PositiveIntegerDirective from "./directive/PositiveIntegerDirective";
import FilterEmoji from "./directive/FilterEmoji";
Vue.directive("positive-integer", PositiveIntegerDirective);
// Vue.directive('filter-emoji', FilterEmoji);

createMicroApp(Vue);
Vue.use(FilterEmoji);
let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
["error", "success", "info", "warning"].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
Vue.prototype.$message = resetMessage;
//优化结束

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
