<template>
  <el-button :type="type" :disabled="disabled" class="button_primary" :plain="plain">
    <i v-if="icon && icon.indexOf('el-icon') >= 0" :class="`${icon}`"></i><i v-else-if="icon && icon.indexOf('el-icon') < 0" :class="`icon iconfont ${icon}`"></i><slot></slot>
  </el-button>
</template>
<script>
  export default {
    name: 'common-button',
    props: ['icon', 'content', 'type', 'disabled', 'plain'],
  };
</script>
<style lang="scss">

</style>
