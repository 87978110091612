import fetch from '../utils/service';
export default {
  // 获取oauth2.0跳转基础配置
  oauthConf (data) {
    return fetch({
      method: 'get',
      url: `/v2/oauth/conf`,
      data
    });
  },
  // 登录
  userLogin (data) {
    return fetch({
      method: 'post',
      url: `/v2/users/token`,
      data
    });
  },
  userLogout (data) {
    return fetch({
      method: 'delete',
      url: `/v2/users/token`,
      data
    });
  },
  // 登录
  userAdminLogin (data) {
    return fetch({
      method: 'post',
      url: `/v2/admin/deploy-service`,
      data
    });
  },
  //用户信息
  getUserInfo (data) {
    return fetch({
      method: 'post',
      url: `/v2/users/info`,
      data
    });
  },
  // 获取任务配置
  getDirectory (data) {
    return fetch({
      method: 'post',
      url: `/automation/get-directory`,
      data
    });
  },
  getUserMenu (data) {
    return fetch({
      method: 'post',
      url: `/user/menu`,
      data
    });
  },
  saveGeneral (data) {
    return fetch({
      method: 'post',
      url: `/automation/save-general`,
      data
    });
  },
  //保存个人模板
  saveTemplate (data) {
    return fetch({
      method: 'post',
      url: `/custom/save-general`,
      data
    });
  },
  // 获取角色的所有个人模版
  getAllTemplate (data) {
    return fetch({
      method: 'post',
      url: `/custom/get-all-custom-info`,
      data
    });
  },
  //删除模版
  deleteTemplate (data) {
    return fetch({
      method: 'post',
      url: `/custom/delete-custom-info`,
      data
    });
  },
  //根据模版key获取模版信息
  getTemplateInfo (data) {
    return fetch({
      method: 'post',
      url: `/custom/get-one-custom-info`,
      data
    });
  },
  getMarkConf (data) {
    return fetch({
      method: 'post',
      url: `/automation/get-taskinfo`,
      data
    });
  },
  getTaskguildinfo (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-task-guildinfo`,
      data
    });
  },
  getTaskDistribute (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/ajax-package-distribute-free-task`,
      data
    });
  },
  ajaxUpload (data) {
    return fetch({
      method: 'post',
      url: `/automation/ajaxupload`,
      data
    });
  },
  uploadByNum (data) {
    return fetch({
      method: 'post',
      url: `/automation/upload-by-num`,
      data
    });
  },
  getUploadStatus (data) {
    return fetch({
      method: 'post',
      url: `/automation/get-upload-status`,
      data
    });
  },
  getUploadLog (data) {
    return fetch({
      method: 'post',
      url: `/automation/get-upload-log`,
      data
    });
  },
  getBatchList (data) {
    return fetch({
      method: 'post',
      url: `/automation/get-upload-log`,
      data
    });
  },
  getDataCleanType (data) {
    return fetch({
      method: 'post',
      url: `/automation/data-clean-type`,
      data
    });
  },
  getDataClassificationType (data) {
    return fetch({
      method: 'post',
      url: `/automation/data-classification-type`,
      data
    });
  },
  
  getPresetDir (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-preset-dir`,
      data
    });
  },
  getPreset (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/get-preset`,
      data
    });
  },
  checkPreset (data) {
    return fetch({
      method: 'post',
      url: `/pm-task/check-preset`,
      data
    });
  },
  wechatSetPwd (data) {
    return fetch({
      method: 'post',
      url: `/user/wechat-set-pwd`,
      data
    });
  },
  automationPreview (data) {
    return fetch({
      method: 'post',
      url: `/automation/preview`,
      data
    });
  },
  getTaskLimit (data) {
    return fetch({
      method: 'post',
      url: `/task/get-task-limit`,
      data
    });
  }
};
