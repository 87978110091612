import { ConfigMgr } from "../utils/config";

class MicrosMgr {
  constructor() {
    const { VITE_MICRO_ADMIN_HOST } = ConfigMgr.instance.getConfig();
    this.baseUrl = VITE_MICRO_ADMIN_HOST;
  }
  // 数据集管理-活动记录-数据清洗日志
  getDataCleanUrl(ds_id) {
    return `${this.baseUrl}/dataset/${ds_id}/record/data-clean`;
  }
  // 数据集管理-绑定项目
  getBindProjectUrl(ds_id) {
    return `${this.baseUrl}/dataset/${ds_id}/bind-project`;
  }
  // 数据集管理-活动记录-数据清洗日志
  getProjectSmartLabelUrl(project_id) {
    return `${this.baseUrl}/project/${project_id}/smart-label`;
  }
}

export const microsMgr = new MicrosMgr();
