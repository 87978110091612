import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import { ConfigMgr } from "../shared/utils/config";
Vue.use(Router);
//解决控制台会报[NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}]
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
const systemMonitor = () => import("@/pages/project/monitor/index");
const systemStatus = () => import("@/pages/project/monitor/systemState/index");
const systemServer = () => import("@/pages/project/monitor/server/index");
const systemStat = () => import("@/pages/project/monitor/stat/index");
// const dataStation = () => import("@/pages/project/dataStation/index");
// const dataSet = () => import("@/pages/project/dataStation/dataSet/index");
//数据管理
const dataManage = () => import("@/pages/project/dataManage/index");
// const dataSetup = () => import("@/pages/project/dataManage/setup/index");
// const managePage = () => import("@/pages/project/dataManage/manage/index");
// const handleRecord = () => import("@/pages/project/dataManage/record/upload/errorList");
const dataHandleRecord = () =>
  import("@/pages/project/task/components/components/errorList");
const dataReviewReport = () =>
  import("@/pages/project/task/components/components/reviewReport/report");
const sequenceFileList = () =>
  import("@/pages/dataset/components/manage/sqFile/index");
const fileDetail = () =>
  import("@/pages/dataset/components/manage/fileDetail/index");
const fileView = () => import("@/pages/dataset/view/index");
const labelCount = () => import("@/pages/project/labelCount/index");
const labelCountPerson = () => import("@/pages/project/labelCount/person");
const labelCountTeam = () => import("@/pages/project/labelCount/team");

// //通用标注
const Home = () => import("@/pages/Home");
const login = () => import("@/pages/login/login");
const signUp = () => import("@/pages/login/signUp");

const thesaurusManage = () => import("@/pages/thesaurusManage/index");
const Error = () => import("@/pages/error/Index");
const libraryDetail = () => import("@/pages/thesaurusManage/detail/index");
const projectList = () => import("@/pages/project/projectList");
const data = () => import("@/pages/project/data/index");
const projectConfig = () => import("@/pages/project/projectConfig");
const projectIndex = () => import("@/pages/project/index");
const projectData = () => import("@/pages/project/projectData");
const userManageIndex = () => import("@/pages/userManage/index");
const organizationManage = () =>
  import("@/pages/userManage/components/organizationManage");
const projectTeamManage = () =>
  import("@/pages/userManage/components/projectTeamManage");
const userManage = () => import("@/pages/userManage/components/userManage");
const organizationUser = () =>
  import("@/pages/userManage/components/components/organizationUser");
const teamUser = () =>
  import("@/pages/userManage/components/components/teamUser");
const taskManage = () => import("@/pages/project/task/taskManage");
const taskIndex = () => import("@/pages/project/task/components/taskIndex");
const performance = () =>
  import("@/pages/project/task/components/components/components/performance");
const taskPerformanceManageDetail = () =>
  import("@/pages/project/task/components/components/teamDetail");
const userLabelPerformanceManage = () =>
  import("@/pages/project/task/components/components/userLabel");
const performanceManage = () =>
  import("@/pages/project/performanceManage/index");
const performanceManageDetail = () =>
  import("@/pages/project/performanceManage/components/teamDetail");
const userLabel = () =>
  import("@/pages/project/performanceManage/components/userLabel");
const webHook = () => import("@/pages/project/webHook/webHook");
const webHookRecord = () =>
  import("@/pages/project/webHook/webHookRecord/webHookRecord");
const model = () => import("@/pages/project/model/model");
// const devTool = () => import('@/pages/project/developerTools/index');
// 数据集
const datasetIndex = () => import("@/pages/dataset/index");
const datasetDetail = () => import("@/pages/dataset/detail/index");
// 活动记录
const recordIndex = () => import("@/pages/dataset/record/index");
const projectRecordIndex = () =>
  import("@/pages/project/dataManage/record/index");

const uploadRecord = () => import("@/pages/components/record/upload");
const handleRecord = () => import("@/pages/components/record/upload/errorList");
const exportRecord = () => import("@/pages/components/record/export");
const operateRecord = () => import("@/pages/components/record/operate");
const cleanRecord = () => import("@/pages/components/record/cleaning");
const operateRecordError = () =>
  import("@/pages/components/record/operate/errorList");
const pushRecord = () => import("@/pages/components/record/push");
const pushRecordError = () =>
  import("@/pages/components/record/push/errorList");
const dataSetUp = () => import("@/pages/dataset/setup");
const bindProject = () => import("@/pages/dataset/bindProject");
const projectDataSetUp = () => import("@/pages/project/dataManage/setup");
const pushHandleRecord = () =>
  import("@/pages/components/record/push/errorList");

const dataStation = () => import("@/pages/dataset/dataStation/index");
const dataSet = () => import("@/pages/dataset/dataStation/dataSet/index");
const managePage = () => import("@/pages/dataset/components/manage/index");
const modelManageIndex = () => import("@/pages/modelManage/index");
const modelManage = () => import("@/pages/modelManage/components/modelManage");

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: `登录`,
    },
  },
  {
    path: "/sign-up",
    name: "signUp",
    component: signUp,
    meta: {
      title: `登录`,
    },
  },

  // 大屏数据可视化
  {
    path: "/dataService-view",
    name: "dataService-view",
    component: data,
    meta: {
      title: `数据可视化`,
    },
  },
  //项目列表页
  {
    path: "/project-list",
    name: "projectList",
    component: projectList,
    meta: {
      title: `项目列表`,
      // keepAlive: true,
      // isBack: false
    },
  },
  // 项目详情页
  {
    path: "/project/:id",
    component: projectIndex,
    name: "project-detail",
    meta: {
      title: `项目详情`,
    },
    children: [
      //项目列表页

      {
        path: "project-config",
        name: "projectConfig",
        component: projectConfig,
        meta: {
          title: `标注配置`,
        },
      },
      {
        path: "project-data",
        name: "projectData",
        component: projectData,
      },
      {
        path: "project-taskManage",
        name: "projectConfig",
        component: taskManage,
        meta: {
          title: `任务管理`,
        },
      },
      {
        path: "project-taskIndex",
        name: "projectConfig",
        component: taskIndex,
        meta: {
          title: `任务详情`,
        },
        children: [
          {
            path: "/",
            name: "projectConfig",
            component: performance,
            meta: {
              title: `绩效管理`,
            },
          },
          {
            path: "performance",
            name: "projectConfig",
            component: performance,
            meta: {
              title: `绩效管理`,
            },
          },
          {
            path: "task-team-detail",
            name: "projectConfig",
            component: taskPerformanceManageDetail,
            meta: {
              title: `绩效管理`,
            },
          },
          {
            path: "user-label",
            name: "projectConfig",
            component: userLabelPerformanceManage,
            meta: {
              title: `绩效管理`,
            },
          },
        ],
      },
      {
        path: "project-performanceManage",
        name: "projectConfig",
        component: performanceManage,
        meta: {
          title: `绩效管理`,
        },
      },
      {
        path: "task-team-detail",
        name: "projectConfig",
        component: performanceManageDetail,
        meta: {
          title: `绩效管理`,
        },
      },
      {
        path: "user-label",
        name: "projectConfig",
        component: userLabel,
        meta: {
          title: `绩效管理`,
        },
      },

      {
        path: "project-dataManage",
        name: "projectConfig",
        component: dataManage,
        meta: {
          title: `数据管理`,
        },
        children: [
          {
            path: "/",
            name: "projectConfig",
            component: managePage,
            meta: {
              title: `数据管理`,
            },
          },
          {
            path: "manage",
            name: "projectConfig",
            component: managePage,
            meta: {
              title: `数据管理`,
            },
          },
          {
            path: "record",
            name: "projectConfig",
            component: projectRecordIndex,
            meta: {
              title: `活动记录`,
            },
            children: [
              {
                path: "/",
                name: "projectConfig",
                component: uploadRecord,
                meta: {
                  title: `上传记录`,
                },
              },
              {
                path: "upload",
                name: "projectConfig",
                component: uploadRecord,
                title: `上传记录`,
              },
              {
                path: "operate",
                name: "projectConfig",
                component: operateRecord,
                meta: {
                  title: `操作日志`,
                },
              },
              {
                path: "upload-error",
                name: "projectConfig",
                component: handleRecord,
                title: `失败记录`,
              },
              {
                path: "export",
                name: "projectConfig",
                component: exportRecord,
                meta: {
                  title: `导出记录`,
                },
              },
              // {
              //   path: "operate",
              //   name: "projectConfig",
              //   component: operateRecord,
              //   meta: {
              //     title: `操作日志`
              //   }
              // },
              {
                path: "push",
                name: "projectConfig",
                component: pushRecord,
                meta: {
                  title: `推送记录`,
                },
              },
              {
                path: "push-handleList",
                name: "projectConfig",
                component: pushRecordError,
                meta: {
                  title: `推送记录`,
                },
              },
            ],
          },
          {
            path: "setup",
            name: "projectConfig",
            component: projectDataSetUp,
            meta: {
              title: `数据管理`,
            },
          },
        ],
      },
      {
        path: "upload-handleList",
        name: "projectConfig",
        component: handleRecord,
        meta: {
          title: `处理记录`,
        },
      },
      {
        path: "push-handleList",
        name: "projectConfig",
        component: pushHandleRecord,
        meta: {
          title: `处理记录`,
        },
      },
      {
        path: "sequence-fileList",
        name: "projectConfig",
        component: sequenceFileList,
        meta: {
          title: `序列的文件列表`,
        },
      },
      {
        path: "fileView",
        name: "projectConfig",
        component: fileView,
        meta: {
          title: `数据可视化`,
        },
      },
      {
        path: "fileDetail",
        name: "projectConfig",
        component: fileDetail,
        meta: {
          title: `文件详情`,
        },
      },
      {
        path: "project-webHook",
        name: "projectConfig",
        component: webHook,
        meta: {
          title: `WebHook`,
        },
      },
      {
        path: "webHook-record",
        name: "projectConfig",
        component: webHookRecord,
        meta: {
          title: `发送记录`,
        },
      },
      {
        path: "project-model",
        name: "projectConfig",
        component: model,
        meta: {
          title: `智能标注`,
        },
      },
      {
        path: "data-handleList",
        name: "projectConfig",
        component: dataHandleRecord,
        meta: {
          title: `处理记录`,
        },
      },
      {
        path: "data-reviewReport",
        name: "projectConfig",
        component: dataReviewReport,
        meta: {
          title: `审阅报告`,
        },
      },
      {
        path: "label-count",
        name: "projectConfig",
        component: labelCount,
        meta: {
          title: `实效分析`,
        },
        redirect: "label-count/team",
        children: [
          {
            path: "person/:gid",
            name: "projectConfig",
            component: labelCountPerson,
            meta: {
              title: `实效分析`,
            },
          },
          {
            path: "team",
            name: "projectConfig",
            component: labelCountTeam,
            meta: {
              title: `实效分析`,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/dataset/:ds_id",
    component: datasetIndex,
    name: "dataset-detail",
    meta: {
      title: `数据详情`,
    },
    children: [
      {
        path: "detail",
        name: "dataset-detail",
        component: datasetDetail,
        meta: {
          title: `数据详情`,
        },
      },
      {
        path: "record",
        // name: "projectConfig",
        component: recordIndex,
        meta: {
          title: `活动记录`,
        },
        children: [
          {
            path: "/",
            name: "record-upload",
            component: uploadRecord,
            meta: {
              title: `上传记录`,
            },
          },
          {
            path: "upload",
            name: "record-upload",
            component: uploadRecord,
            title: `上传记录`,
          },
          {
            path: "upload-error",
            name: "record-upload-error",
            component: handleRecord,
            title: `失败记录`,
          },
          {
            path: "export",
            name: "record-export",
            component: exportRecord,
            meta: {
              title: `导出记录`,
            },
          },
          {
            path: "operate",
            name: "record-operate",
            component: operateRecord,
            meta: {
              title: `操作日志`,
            },
          },
          {
            path: "data-clean",
            name: "data-clean",
            component: cleanRecord,
            meta: {
              title: `操作日志`,
            },
          },
          {
            path: "operate-error",
            name: "record-operate-error",
            component: operateRecordError,
            meta: {
              title: `操作日志`,
            },
          },
        ],
      },
      {
        path: "setup",
        component: dataSetUp,
        meta: {
          title: `设置`,
        },
      },
      {
        path: "bind-project",
        component: bindProject,
        meta: {
          title: `绑定项目`,
        },
      },
      {
        path: "sequence-fileList",
        name: "sequence-fileList",
        component: sequenceFileList,
        meta: {
          title: `序列的文件列表`,
        },
      },
      {
        path: "fileView",
        name: "fileView",
        component: fileView,
        meta: {
          title: `数据可视化`,
        },
      },
      {
        path: "fileDetail",
        name: "fileDetail",
        component: fileDetail,
        meta: {
          title: `文件详情`,
        },
      },
    ],
  },
  {
    path: "/user-manage-index",
    name: "user-manage-index",
    component: userManageIndex,
    meta: {
      title: `用户管理`,
    },
    children: [
      {
        path: "organizationManage",
        name: "organizationManage",
        component: organizationManage,
        meta: {
          title: `组织管理`,
        },
      },
      {
        path: "projectTeamManage",
        name: "projectTeamManage",
        component: projectTeamManage,
        meta: {
          title: `团队管理`,
        },
      },
      {
        path: "userManage",
        name: "userManage",
        component: userManage,
        meta: {
          title: `用户管理`,
        },
      },
      {
        path: "organizationUser",
        name: "organizationUser",
        component: organizationUser,
        meta: {
          title: `组织用户`,
        },
      },
      {
        path: "teamUser",
        name: "teamUser",
        component: teamUser,
        meta: {
          title: `团队用户`,
        },
      },
    ],
  },
  {
    path: "/model-manage-index",
    name: "model-manage-index",
    component: modelManageIndex,
    children: [
      {
        path: "modelManage",
        name: "modelManage",
        component: modelManage,
        meta: {
          title: `用户管理`,
        },
      },
    ],
  },
  {
    path: "/monitor",
    name: "monitorManage",
    component: systemMonitor,
    meta: {
      title: `系统数据`,
    },
    children: [
      {
        path: "/",
        name: "monitorManage",
        component: systemStatus,
        meta: {
          title: `系统状态`,
        },
      },
      {
        path: "status",
        name: "monitorManage",
        component: systemStatus,
        meta: {
          title: `系统状态`,
        },
      },
      {
        path: "server",
        name: "monitorManage",
        component: systemServer,
        meta: {
          title: `服务器`,
        },
      },
      {
        path: "stat",
        name: "stat",
        component: systemStat,
        meta: {
          title: `天统计`,
        },
      },
    ],
  },
  {
    path: "/data-station",
    name: "dataStation",
    component: dataStation,
    meta: {
      title: `数据管理`,
    },
    children: [
      {
        path: "/",
        name: "dataStation",
        component: dataSet,
        meta: {
          title: `数据管理`,
        },
      },
      {
        path: "dataset",
        name: "dataStation",
        component: dataSet,
        meta: {
          title: `数据管理`,
        },
      },
    ],
  },

  {
    path: "/error",
    component: Error,
    name: "ErrorPages",
    meta: {
      title: "Error Pages",
      icon: "404",
    },
    children: [
      // {
      //   path: '401',
      //   component: () => import('@/pages/error/404'),
      //   name: 'Page401',
      //   meta: { title: '401', noCache: true }
      // },
      {
        path: "404",
        component: () => import("@/pages/error/404"),
        name: "Page404",
        meta: { title: "404", noCache: true },
      },
    ],
  },
  // 词库管理
  {
    path: "/thesaurus-manage",
    name: "thesaurusManage",
    component: thesaurusManage,
    meta: {
      title: "词库管理",
    },
  },
  {
    path: "/pm-library-detail",
    component: libraryDetail,
    name: "libraryDetail",
    meta: { title: "词库详情" },
  },

  // {
  //   path: "*", // 页面不存在的情况下会跳到404页面
  //   redirect: "/error/404",
  //   name: "notFound",
  //   hidden: true
  // }
];

const router = new Router({
  mode: "history",
  base: ConfigMgr.instance.getConfig().VITE_PUBLIC_PATH,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 20);
      });
    } else {
      const ary = ["Invest", "Store"]; // 这里是需要缓存的页面的route的name，不是vue类的name

      if (ary.includes(from.name)) {
        /* 最近一次回顾的时候 发现了一个问题
         * 如果是列表页跳转到详情页，这个时候时候其实已经到了详情页面，
         * 如果当当前详情页的页面的高度没有列表页面跳转进来时滚动的高度高的时候，这个时候就会获取不真正的页面高度，然后合成事件回退的时候就会滚动不到跳转前的位置
         * 解决的办法就是在每个页面离开前，获取到页面的高度，存到的meta上，这样既能解决这个问题，也能结局把不是body滚动的情况获取不到滚动高度的问题。
         */
        from.meta.scrollTop = document.documentElement.scrollTop;
      }
      return { x: 0, y: to.meta.scrollTop || 0 };
    }
  },
});

function setTitle(to) {
  const config = window.__G_CONFIG__;
  if (config.title) {
    document.title = `${config.title} - ${to.meta.title}`;
  } else {
    document.title = `${to.meta.title}`;
  }
  localStorage.setItem("config", JSON.stringify(config));
}
// function getHost (to) {
//   axios({
//     method: 'get',
//     url: "/w/customer_config/host.json",
//     dataType: "json",
//     crossDomain: true,
//     cache: false}).then(res => {
//       if (res.status == 200) {
//         // 获取内容
//         let route = res.data;
//         // console.log(res.data);
//         let host = window.location.host;
//         // console.log(host);
//         // console.log(route[host]);
//         if (route[host]) {
//           setConfig(to, route[host]);
//         } else {
//           setConfig(to);
//         }
//       } else {
//         // 获取config.json
//         setConfig(to);
//       }
//   }).catch(res => {
//     setConfig(to);
//   });
// }
// function setConfig (to, file_name) {
//   console.log('file_name',file_name);
//   axios({
//     method: 'get',
//     url: `/${file_name ? file_name : 'config.json'}`,
//     dataType: "json",
//     crossDomain: true,
//     cache: false}).then(res => {
//       if (res.status == 200) {
//         let config = res.data;
//         if (typeof(config) == 'string') {
//           if (configJson.title) {
//             document.title = `${configJson.title} - ${to.meta.title}`;
//           } else {
//             document.title = `${to.meta.title}`;
//           }

//           localStorage.setItem("config", JSON.stringify(configJson));
//         } else {
//           if (config.title) {
//             document.title = `${config.title} - ${to.meta.title}`;
//           } else {
//             document.title = `${to.meta.title}`;
//           }
//           localStorage.setItem("config", JSON.stringify(config));
//         }

//       } else {
//         if (configJson.title) {
//           document.title = `${configJson.title} - ${to.meta.title}`;
//         } else {
//           document.title = `${to.meta.title}`;
//         }

//         localStorage.setItem("config", JSON.stringify(configJson));
//       }
//   })
//   .catch(res => {
//     // 走本地的配置
//     if (configJson.title) {
//       document.title = `${configJson.title} - ${to.meta.title}`;
//     } else {
//       document.title = `${to.meta.title}`;
//     }

//     localStorage.setItem("config", JSON.stringify(configJson));
//   });
// }
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.getElementById("app").scrollTop = 0;
  if (to.meta.title) {
    setTitle(to);
  }
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  // handleKeepAlive();
  next();
});

// async function handleKeepAlive (to) {
//   if (to.matched && to.matched.length > 2) {
//     for (let i = 0; i < to.matched.length; i++) {
//       const element = to.matched[i];
//       console.log(element.component.default.name);
//       if (element.component.default.name === '') {
//         to.matched.splice(i, 1);
//         await handleKeepAlive(to);
//       }
//       if (typeof element.component.default === 'function') {
//         await element.component.default();
//         await handleKeepAlive(to);
//       }
//     }
//   }
// }
export default router;
