
import {
  CHANGE_NUMFLAG,
} from './mutation-types';

export default {
  [CHANGE_NUMFLAG] (state, numFlag) {
    state.numFlag++;
  },
  changeIncludes (state, payload) {
    state.includes = payload;
  },
  changeProjectInfo (state, payload) {
    state.project_info = payload;
  },
  changeDatasetInfo (state, payload) {
    state.dataset_info = payload;
  }
};
