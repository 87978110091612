export class ConfigMgr {
  static _instance = null;

  static get instance() {
    if (!this._instance) {
      this._instance = new ConfigMgr();
    }

    return this._instance;
  }

  constructor() {
    const envs = import.meta.env;
    this.config = { ...envs };
  }

  getConfig() {
    return this.config;
  }
}
