<template>
<el-drawer
     title="修改密码"
     :append-to-body="true"
     :visible.sync="options.distributeDrawer"
     custom-class="editpwd_drawer"
     size="50%"
     >
    <el-form :validate-on-rule-change="false" :model="ruleForm" :rules="rules" ref="ruleForm" class="editPwd_ruleForm">
        <el-form-item key="userName" label="账号" prop="userName">
          <el-input v-model="ruleForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item key="nickName" label="昵称" prop="nickName">
          <el-input  placeholder="请输入昵称" v-model="ruleForm.nickName"></el-input>
        </el-form-item>
        <el-form-item key="oldPwd" label="原密码" prop="oldPwd">
          <el-input :type="oldInputType ? 'password':''"  placeholder="请输入原密码" v-model="ruleForm.oldPwd">
            <svg class="icon" aria-hidden="true" slot="suffix" @click="switchPassword('oldInputType')">
              <use :xlink:href="oldInputType ? '#iconyincangxianshi':'#iconxianshi1'"></use>
            </svg>
          </el-input>
        </el-form-item>
        <el-form-item key="newPwd" label="新密码" prop="newPwd">
          <el-input :type="newInputType ? 'password':''"  placeholder="请输不少于8位，含数字、英文大小写的混合密码" v-model="ruleForm.newPwd">
            <svg class="icon" aria-hidden="true" slot="suffix" @click="switchPassword('newInputType')">
              <use :xlink:href="newInputType ? '#iconyincangxianshi':'#iconxianshi1'"></use>
            </svg>
          </el-input>
        </el-form-item>
        <el-form-item key="confirmPwd" label="确认密码" prop="confirmPwd">
          <el-input :type="secondInputType ? 'password':''"  placeholder="请输入新密码" v-model="ruleForm.confirmPwd" @blur="confirmPwdRule('ruleForm')">
            <svg class="icon" aria-hidden="true" slot="suffix" @click="switchPassword('secondInputType')">
              <use :xlink:href="secondInputType ? '#iconyincangxianshi':'#iconxianshi1'"></use>
            </svg>
          </el-input>
        </el-form-item>
    </el-form>
    <div class="drawer__footer">
      <common-button type="primary" @click.native="submitForm('ruleForm')">确 认</common-button>
      <common-button @click.native="resetForm('ruleForm')">取 消</common-button>
    </div>
</el-drawer>
</template>
<script>
  import Ajax from '@/api/user.js';
  import md5 from 'js-md5';
  export default {
    data () {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if (value !== this.ruleForm.newPwd) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        oldInputType: true,
        newInputType: true,
        secondInputType: true,
        ruleForm: {
          userName: '',
          nickName: '',
          oldPwd: '',
          newPwd: '',
          confirmPwd: '',
        },
        rules: {
          userName: [],
          nickName: [
            // {
            //   validator: function (rule, val, callback) {
            //     if (val.length > 15) {
            //       return callback(new Error('用户名称不能超过15个字'));
            //     } else {
            //       callback();
            //     }
            //   },
            //   trigger: 'blur'
            // },
              { required: true, message: '请输入用户名称', trigger: 'blur' },
          ],
          oldPwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
          newPwd: [
            {
              required: true,
              validator: function (rule, val, callback) {
                let reg = /^[0-9a-zA-Z]{8,}$/;
                if (!reg.test(val)) {
                  return callback(new Error('密码格式不正确'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            },
                { required: true, message: '请输入新密码', trigger: 'blur' },
          ],
          confirmPwd: [
              { required: true, validator: validatePass, trigger: 'blur' },
          ],
        }
      };
    },
    props: ['options'],
    mounted () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.ruleForm.nickName = userInfo.nickname ? userInfo.nickname : '';
      this.ruleForm.userName = userInfo.user_name ? userInfo.user_name : '';
    },
    methods: {
      confirmPwdRule (formName) {
        // console.log(222);
        // this.$refs[formName].validateField('confirmPwd',res=>{
        //   if(){

        //   }
        // })
      },
      submitForm (formName) {
        let vm = this;
        let userId = JSON.parse(localStorage.getItem('userInfo')).user_id;
        vm.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              user_id: userId,
              new_passwd: md5(vm.ruleForm.newPwd),
              old_passwd: md5(vm.ruleForm.oldPwd),
              nickname: vm.ruleForm.nickName,
            };
            Ajax.changeUser(params).then(res => {
              if (res.code == '') {
                vm.$message({
                  type: 'success',
                  message: '操作成功',
                  offset: '113'
                });
                vm.options.distributeDrawer = false;
              } else {
                vm.$message({
                  type: 'error',
                  message: res.msg,
                  offset: '113'
                });
              }
            });
          }
        });
      },
      resetForm () {
        this.options.distributeDrawer = false;
      },
      switchPassword (type) {
        if (type == 'oldInputType') {
          this.oldInputType = !this.oldInputType;
        } else if (type == 'newInputType') {
          this.newInputType = !this.newInputType;
        } else {
          this.secondInputType = !this.secondInputType;
        }
      },
    }
  };
</script>

<style lang="scss">
.editpwd_drawer{
  .el-drawer__body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>

