export const FilterEmojiDirective = {
  bind (el, binding) {
    const inputElement = el.querySelector('input');
    const errorElement = document.createElement('div');
    errorElement.className = 'el-form-item__error'; // Element UI错误消息样式
    inputElement.parentNode.appendChild(errorElement);
    alert(3333);
    inputElement.addEventListener('input', () => {
      const text = inputElement.value;
      const regex = /[^\x00-\x7F]+/g; // 匹配非ASCII字符（可能是表情符号）的正则表达式
      console.log(88888);
      if (regex.test(text)) {
        const cleanedText = text.replace(regex, '1'); // 清除非ASCII字符（可能是表情符号）
        inputElement.value = cleanedText;
        inputElement.dispatchEvent(new Event('input')); // 触发输入事件，以确保更新数据模型
        errorElement.textContent = "请不要输入表情符号";
      } else {
        errorElement.textContent = "";
      }
    });
  },
};

export default {
  install (Vue) {
    Vue.directive('filter-emoji', FilterEmojiDirective);
  },
};