/**
 * 配置以子应用为主
 *  O_xxx 标识子应用监听主应用到messgae
 *  S_xxx 标识子应用发送到主应用到messgae
 */
export const MICRO_KEYS = {
  O_BOOTSTARP: "O_BOOTSTARP", // 启动
  S_ON_LOAD: "S_ON_LOAD", // 加载完成
  S_LOGIN_OUT: "S_LOGIN_OUT", // 登陆失效

  S_DATASET_GO_DATASET_DEATIL: "S_DATASET_GO_DATASET_DEATIL", // 跳转 序列详情
  S_PROJECT_SHOW_GO_PROJECT_CONFIG_MODAL:
    "S_PROJECT_SHOW_GO_PROJECT_CONFIG_MODAL", // 展示 前往项目标注配置弹出框

  O_PROJECT_GET_LIST: "O_PROJECT_GET_LIST", //项目管理/绑定项目-> 刷新项目列表
  S_PROJECT_DELETE: "S_PROJECT_DELETE", //项目管理/绑定项目-> 删除项目
  S_PROJECT_SETTING: "S_PROJECT_SETTING", //项目管理/绑定项目-> 项目设置
  S_PROJECT_ENTER_DEATIL: "S_PROJECT_ENTER_DEATIL", //项目管理/绑定项目-> 进入项目详情
};
