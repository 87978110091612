let watermark = {};
let setWatermark = function(settings) {
  // let id = '1.23452384164.123412415'

  // if (document.getElementById(id) !== null) {
  //   document.body.removeChild(document.getElementById(id))
  // }

  // let can = document.createElement('canvas')
  // can.width = 300
  // can.height = 200

  // let cans = can.getContext('2d')
  // cans.rotate(-20 * Math.PI / 180)
  // cans.font = '14px 400'
  // cans.fillStyle = 'rgb(187,187,187)'
  // cans.textAlign = 'left'
  // cans.textBaseline = 'Middle'
  // cans.fillText(str, can.width / 20, can.height )

  // let div = document.createElement('div')
  // div.id = id
  // div.style.pointerEvents = 'none'
  // div.style.top = '3px'
  // div.style.left = '0px'
  // div.style.position = 'fixed'
  // div.style.zIndex = '100000'
  // div.style.width = document.documentElement.clientWidth  + 'px'
  // div.style.height = document.documentElement.clientHeight  + 'px'
  // div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  // document.body.appendChild(div)
  // return id

      //默认设置
  var defaultSettings = {
    watermark_txt: settings.watermark_txt,
    watermark_x: 20, //水印起始位置x轴坐标
    watermark_y: 80, //水印起始位置Y轴坐标
    watermark_rows: settings.rows, //水印行数
    watermark_cols: 200, //水印列数
    watermark_x_space: 50, //水印x轴间隔
    watermark_y_space: 80, //水印y轴间隔
    watermark_color: '#E8E8E8', //水印字体颜色
    watermark_alpha: 0.45, //水印透明度
    watermark_fontsize: '12px', //水印字体大小
    watermark_font: 'PingFang', //水印字体
    watermark_width: 180, //水印宽度
    watermark_height: 80, //水印长度
    watermark_angle: 20//水印倾斜度数
  };
      //采用配置项替换默认值，作用类似jquery.extend
  if (arguments.length === 1 && typeof arguments[0] === "object")
      {
    var src = arguments[0] || {};
    for (var key in src)
          {
      if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])
        {continue;}
      else if (src[key])
        {defaultSettings[key] = src[key];}
    }
  }
       // document.getElementById('watermark').innerHTML = '';
  var oTemp = document.createDocumentFragment();
  if (document.getElementById('watermark')) {
    document.body.removeChild(document.getElementById('watermark'));
  }

  let watermark = document.createElement('div');
  watermark.id = 'watermark';
  document.body.appendChild(watermark);
      //获取页面最大宽度
  var page_width = Math.max(document.body.scrollWidth, document.body.clientWidth);
  var cutWidth = page_width * 0.0150;
  var page_width = page_width - cutWidth;
      //获取页面最大高度
  var page_height = Math.max(document.body.scrollHeight, document.body.clientHeight) + 450;
      // var page_height = document.body.scrollHeight+document.body.scrollTop;
      //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
  if (defaultSettings.watermark_cols == 0 || (parseInt(defaultSettings.watermark_x + defaultSettings.watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1)) > page_width)) {
    defaultSettings.watermark_cols = parseInt((page_width - defaultSettings.watermark_x + defaultSettings.watermark_x_space) / (defaultSettings.watermark_width + defaultSettings.watermark_x_space));
    defaultSettings.watermark_x_space = parseInt((page_width - defaultSettings.watermark_x - defaultSettings.watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1));
  }
      //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
  if (defaultSettings.watermark_rows == 0 || (parseInt(defaultSettings.watermark_y + defaultSettings.watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)) > page_height)) {
    defaultSettings.watermark_rows = parseInt((defaultSettings.watermark_y_space + page_height - defaultSettings.watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space));
    defaultSettings.watermark_y_space = parseInt(((page_height - defaultSettings.watermark_y) - defaultSettings.watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1));
  }
  var x;
  var y;
  for (var i = 0; i < defaultSettings.watermark_rows; i++) {
    y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
    for (var j = 0; j < defaultSettings.watermark_cols; j++) {
      x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;

      var mask_div = document.createElement('div');
      mask_div.id = 'mask_div' + i + j;
      mask_div.className = 'mask_div';
      mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
              //设置水印div倾斜显示
      mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.visibility = "";
      mask_div.style.position = "absolute";
      mask_div.style.left = x + 'px';
      mask_div.style.top = y + 'px';
      mask_div.style.overflow = "hidden";
      mask_div.style.zIndex = "9999";
      mask_div.style.pointerEvents = 'none';//pointer-events:none  让水印不遮挡页面的点击事件
              //mask_div.style.border="solid #eee 1px";
      mask_div.style.opacity = defaultSettings.watermark_alpha;
      mask_div.style.fontSize = defaultSettings.watermark_fontsize;
      mask_div.style.fontFamily = defaultSettings.watermark_font;
      mask_div.style.color = defaultSettings.watermark_color;
      mask_div.style.textAlign = "center";
      mask_div.style.width = defaultSettings.watermark_width + 'px';
      mask_div.style.height = defaultSettings.watermark_height + 'px';
      mask_div.style.display = "block";
      mask_div.style.letterSpacing = "1px";
      oTemp.appendChild(mask_div);
    }
  }
      // console.log(oTemp)
      // this.$refs.watermark.appendChild(oTemp)
  watermark.appendChild(oTemp);
      // return oTemp;
};

// 该方法只允许调用一次
watermark.set = (str) => {
  setWatermark(str);
  // let id = setWatermark(str)
  // setInterval(() => {
  //   if (document.getElementById(id) === null) {
  //     id = setWatermark(str)
  //   }
  // }, 2000)
  window.onresize = () => {
    setWatermark(str);
  };
};

export default watermark;
