
import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

Vue.use(Vuex);

const state = {
  numFlag: 0,
  bg_img: [],
  bg_imgs: [],
  pointcloud: [],
  project_info: {},
  dataset_info: {},
  includes: ['projectList']
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});


